import { React, useLayoutEffect } from "react";
import Footer from "../component/Footer";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion";
import "../CSS/Seva.css";
import seva from "../Images/gau-seva.jpg";

// import flute from "../Images/flute.png";
// import krish from "../Images/banq.jpg";
// import { Link } from "react-router-dom";
const Seva = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <section class="aboutInfoSection pt-5">
        <div class="container">
          <div class="aboutHomeHead">
          {/* <div className="bannerLogo">
        <img src={flute} alt=""/>
       </div> */}
            <h2 class="">Sacred Service and Spiritual Fulfillment</h2>
          </div>
        </div>
      </section>

      <section id="whatWeDid" className=" contentRowPad">
        <div className="container">
          <div className="row">
            <div className="col-lg-6  col-md-4 col-12 tab_menu">
              <div className="row m0"></div>
            </div>
            <div className="col-lg-6 col-md-8 col-12 tab-contents">
              <div className="tab-content">
                <div role="tabpanel" className="plan-discription" id="wwd2018">
                  {/* <h4>A place of peace and sprituality</h4> */}
                  <h3>Seva is an expression of Bhakti</h3>

                  <p>
                    A multitude of seva offerings await those who seek to engage
                    in acts of selfless service. These sacred services, deeply
                    rooted in ancient traditions, are the lifeblood of this
                    revered institution, embodying the temple's core values of
                    compassion, devotion and community welfare.
                  </p>
                  <p>
                    Each seva represents a unique opportunity for devotees to
                    connect with their faith on a profound level while
                    simultaneously serving the needs of the temple and its
                    community. From the gentle care of the temple's sacred cows
                    in the Gau Seva to the meticulous upkeep of the temple
                    premises, these acts of devotion and service not only enrich
                    the spiritual tapestry of the temple but also provide a
                    pathway to enlightenment and fulfillment for those who
                    partake in them. Embark on a journey of selfless service,
                    where the act of giving becomes a sacred ritual, and the
                    bonds of community grow stronger with every seva offered.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* =============== Donation list ======== */}
      <div className="services mt-5 mb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-lg-1 order-2">
              <div role="tabpanel" className="plan-discription" id="wwd2018">
                {/* <h4>A place of peace and sprituality</h4> */}
                <h3>Different types of Sevas</h3>

                <p>
                  At our temple, we offer a variety of seva opportunities to
                  cater to the diverse spiritual needs and preferences of our
                  devotees. These sevas can be broadly categorized into three
                  distinct types:
                </p>
              </div>
              <Accordion>
              <Accordion.Item eventKey="1">
                  <Accordion.Header>Regular Seva</Accordion.Header>
                  <Accordion.Body>
                    <div className="donation_list">
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th> Amount</th>
                            {/* <th>Donate</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="meals">
                              <h5>Aarthi</h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>
                                ₹ 30
                                {/* <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a> */}
                              </h2>
                            </td>
                            {/* <td class="Donates-btn">
                       
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>
                                Sanna Panchakajjaya (Kadale Panchakajjaya-
                                Small)
                              </h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 50</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>Laddu Seva (2)</h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 100</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>Kadale panchaayakajj</h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 50</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>
                                Vishnusahasranama Tulsi Archana (Ekadasi Days)
                              </h5>{" "}
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 150</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>Govu Grasa Seva (Madhyama)</h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 250</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>Sri Vishnu Sahasranama Archane</h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 50</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>Govu Grasa Seva (One day) </h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 500</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>Samoohika Ganahomam (Tuesdays) </h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 500</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>

                          <tr>
                            <td class="meals">
                              <h5>Vahana Pooja</h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 500</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>Nandadeepa Ghee</h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 1,000</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>
                                Samoohika Sri Satyanarayana Pooja (Poornima
                                days)
                              </h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 1,000</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>Samoohika Sri Shani Pooja (Amavasya days)</h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 1,000</h2>
                            </td>
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>Nitya Pooja </h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 1,500</h2>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Advance Booking Sevas</Accordion.Header>
                  <Accordion.Body>
                    <div className="donation_list">
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th> Amount</th>
                            {/* <th>Donate</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="meals">
                              <h5>Panchamrutha Abhisheka</h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>
                                ₹ 100
                                {/* <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a> */}
                              </h2>
                            </td>
                            {/* <td class="Donates-btn">
                       
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>Sri Krishna Ashtotra Archana</h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 100</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>Avalakki - Poha - Panchakajjaya</h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 200</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>Kalasha Snana</h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 600</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>Ksheera Payasa (Morning & Noon)</h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 600</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>Alankara Seve</h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 1,000</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>Govu Pooja</h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 1,000</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>
                                Hayagriva Maddi Seve with Vishnu Sahasranam
                              </h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 1,500</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>
                                Narayana Hrudaya Laxmi Hrudaya Parayana
                                (Samputikaranaritiya) - Devi Mahatmyam
                              </h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 1,800</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>Rangapuje (Evening)</h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 6,000</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>Sarva Seve / Udyaasthamana Puja</h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 4,000</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>Tulabhara Seva</h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 4,000</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>Goovu Daana Seva (Madhyama)</h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 5,000</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>Thottilu Pooja Seva (Silver Cradle)</h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 8,000</h2>
                            </td>
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>Bhagwan's Batti Seva (One month)</h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 9,000</h2>
                            </td>
                          </tr>{" "}
                          <tr>
                            <td class="meals">
                              <h5>Annaprasada (One day)</h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 10,000</h2>
                            </td>
                          </tr>{" "}
                          <tr>
                            <td class="meals">
                              <h5>Thottilu Pooja Seva (Silver Cradle)</h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 8,000</h2>
                            </td>
                          </tr>{" "}
                          <tr>
                            <td class="meals">
                              <h5>
                                Gokulanidhi (Nitya pooja 10 years - one day per
                                year)
                              </h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 8,000</h2>
                            </td>
                          </tr>{" "}
                          <tr>
                            <td class="meals">
                              <h5>Goovu Daana Seva (Uttama)</h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 10,000</h2>
                            </td>
                          </tr>{" "}
                          <tr>
                            <td class="meals">
                              <h5>
                                Bhagwan's Flower Decoration Seva (one Month)
                              </h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 15,000</h2>
                            </td>
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>Govu Grasa Seva (one Month)</h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 15,000</h2>
                            </td>
                          </tr>{" "}
                          <tr>
                            <td class="meals">
                              <h5>
                                Bhagwan's Ghee Seva (one Month) (1 litre per
                                day)
                              </h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 21,000</h2>
                            </td>
                          </tr>{" "}
                          <tr>
                            <td class="meals">
                              <h5>Bhagwan's Prasada Seva (one Month)</h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 30,000</h2>
                            </td>
                          </tr>{" "}
                          <tr>
                            <td class="meals">
                              <h5>Pallaki Seva</h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 30,000</h2>
                            </td>
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>
                                Bhagwan's Ghee Seva (one Month) (2 litres per
                                day)
                              </h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 42,000</h2>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
               
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Long Term Seva</Accordion.Header>
                  <Accordion.Body>
                    <div className="donation_list">
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th> Amount</th>
                            {/* <th>Donate</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="meals">
                              <h5>
                                Annadana Prasada Seva + Nitya Pooja - One day
                                seva every year for 10 years (100 people){" "}
                              </h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>
                                ₹ 2,00,000
                                {/* <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a> */}
                              </h2>
                            </td>
                            {/* <td class="Donates-btn">
                       
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>
                                Annadana Prasada Seva - One month Seva (100
                                people)
                              </h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 3,00,000</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>
                                Avalakki - Poha - PanchakajjayaAnnadana Prasada
                                Seva Sponsorship for entire year (100 people)
                              </h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 36,00,000</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>
                                Ranga Pooja + Dinner for 10 people <br /> (one
                                day every year for 10 year)
                              </h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 1,50,000</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>
                                Nitya Pooja + Tottilu Seva + Dinner for 10
                                people <br /> (one day every year for 10 years)
                              </h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 2,00,000</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>
                                Ranga Pooja + Tottilu Seva + Dinner for 10
                                people
                                <br /> (one day every year for 10 years)
                              </h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 3,00,000</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>
                                Nitya Pooja + Pallaki Seva + Dinner for 10
                                people
                                <br />
                                (one day every year for 10 years)
                              </h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 6,00,000</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>
                          <tr>
                            <td class="meals">
                              <h5>
                                Nitya Pooja + Ranga Pooja+Tottilu Seva + Pallaki
                                Seva +<br />
                                Dinner for 10 people (one day every year for 10
                                years)
                              </h5>
                              <span class="tooltiptext">Tooltip text</span>
                            </td>
                            <td class="Donate-amount">
                              <h2>₹ 9,00,000</h2>
                            </td>
                            {/* <td class="Donates-btn">
                        <a class="btn" href="/Contact">
                          <Link to="/Contact">Donate</Link>
                        </a>
                      </td> */}
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="col-md-6 order-lg-2 order-1">
              <div className="sevaImg">
                <img src={seva} alt=""/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Seva;
