import React from 'react'
import Footer from "../component/Footer";
import m1 from "../Images/compressed-images (9)/jpeg-optimizer_1.JPG";
import m2 from "../Images/compressed-images (9)/jpeg-optimizer_2.JPG";
import m3 from "../Images/compressed-images (9)/jpeg-optimizer_3.JPG";
import m4 from "../Images/compressed-images (9)/jpeg-optimizer_4.JPG";
import m5 from "../Images/compressed-images (9)/jpeg-optimizer_5.JPG";
import m6 from "../Images/compressed-images (9)/jpeg-optimizer_6.JPG";
import m7 from "../Images/compressed-images (9)/jpeg-optimizer_7.JPG";
import m8 from "../Images/compressed-images (9)/jpeg-optimizer_8.JPG";
import m9 from "../Images/compressed-images (9)/jpeg-optimizer_9.JPG";
import m10 from "../Images/compressed-images (9)/jpeg-optimizer_10.JPG";
import m11 from "../Images/compressed-images (9)/jpeg-optimizer_11.JPG";
import m12 from "../Images/compressed-images (9)/jpeg-optimizer_12.JPG";
import m13 from "../Images/compressed-images (9)/jpeg-optimizer_13.JPG";
import m14 from "../Images/compressed-images (9)/jpeg-optimizer_14.JPG";
import m15 from "../Images/compressed-images (9)/jpeg-optimizer_15.JPG";
import m16 from "../Images/compressed-images (9)/jpeg-optimizer_16.JPG";
import m17 from "../Images/compressed-images (9)/jpeg-optimizer_17.JPG";
import m18 from "../Images/compressed-images (9)/jpeg-optimizer_18.JPG";
import m19 from "../Images/compressed-images (9)/jpeg-optimizer_19.JPG";
import m20 from "../Images/compressed-images (9)/20.JPG";
import m21 from "../Images/compressed-images (9)/21.JPG";
import m22 from "../Images/compressed-images (9)/22.JPG";
import m23 from "../Images/compressed-images (9)/23.JPG";
import m24 from "../Images/compressed-images (9)/24.JPG";
import m25 from "../Images/compressed-images (9)/25.JPG";
import m26 from "../Images/compressed-images (9)/26.JPG";
import m27 from "../Images/compressed-images (9)/27.JPG";
import m28 from "../Images/compressed-images (9)/28.JPG";

import { Link } from "react-router-dom";
const Mahadwara = () => {
  return (
    <div>
         {/* ======== SEO end ======= */}
         <section className="aboutInfoSection pt-5" id="eight">
        <div className="container">
          <div className="aboutHomeHead">
            <div className="bannerLogo">
              <img src="" alt="" />
            </div>
            <h2>Mahadwaras </h2>
          </div>
        </div>
      </section>

      {/* -----------------1.Udupi Sri Krishna-------------------- */}

      <section id="whatWeDid" className=" contentRowPad">
        <div className="container">
          <div className="row pt-5">
            <div className="col-lg-6 col-12 order-lg-1 order-2" id="gokulhall">
              <div className="mahaImg">
                <img src={m1} alt="banquet halls" />
              </div>
            </div>
            <div className="col-lg-6 col-12 order-lg-2 order-3 ">
              <div className="mahaImg">
                <img src={m2} alt="banquet halls" />
              </div>
            </div>
           
            {/* ---------------------------------2.Raja Gopala------------------ */}
           
            <div className="col-lg-6 col-12 tab-contents order-lg-3 order-2 pt-lg-5 pt-2">
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active">
                  <h3>1. Udupi Sri Krishna</h3>
                  <p className="d-none">gokulhall</p>
                  <p>
                  The deity of Lord Sri Krishna in Udupi was installed by Sri Madhwaacharya. This beautiful idol of Lord Krishna is depicted as a small boy.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 tab-contents order-lg-4 order-4 pt-lg-5 pt-2" id="saraswati-auditorium">
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className="">2. Raja Gopala</h3>

                  <p>
                  Lord Krishna as Raja Gopala. "Raja" means king or prince, and "Gopala" refers to a cowherd or one who protects cows. 
                  In this form, Lord Krishna is depicted as a young child or infant playing the role of a king. Raja Gopala is a divine form of Lord Krishna that symbolizes his royal and playful nature. 
                  </p>
                </div>
              </div>
            </div>
          </div>
            
          
            {/* ----------------------Bayali------------------ */}
            <div className="row pt-5" id="valmiki">
              <div className="col-lg-6 col-12 order-lg-1 order-2" id="">
                <div className="mahaImg">
                  <img src={m3} alt="banquet halls" />
                </div>
              </div>
              <div className="col-lg-6 col-12 order-lg-2 order-3">
                <div className="mahaImg">
                  <img src={m4} alt="banquet halls" />
                </div>
              </div>
              <div className="col-lg-6 col-12 tab-contents order-lg-3 order-2 pt-lg-5 pt-2">
                <div className="tab-content">
                  <div role="tabpanel" className="tab-pane active" id="wwd2018">
                    <h3 className="">3. Bayali Bhu Jagava Torida</h3>

                    <p>
                    When mother Yashoda accused little Krishna of stealing the butter and asked him sternly to open his mouth to catch him red-handed, even as he denied it, she was astonished to see, as she gazed into Krishna's mouth, the entire universe within it. She saw mountains, rivers, celestial beings, and even herself. It was a divine vision that revealed Krishna's true nature as the Supreme Lord.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-12 tab-contents order-lg-4 order-4 pt-lg-5 pt-2"
                id="kiran-bala-shagrithaya-hall"
              >
                <div className="tab-content">
                  <div role="tabpanel" className="tab-pane active" id="wwd2018">
                    <h3 className="">4. Kuvalayapeeda</h3>

                    <p>
                    Kamsa had arranged a rogue elephant Kuvalayapeeda to kill Balarama and Krishna as they entered the wrestling arena. Krishna and Balarama with their manoeuvres outwitted the elephant and killed it.
                    </p>
                  </div>
                </div>
              </div>
              
            </div>
            {/* -------------------------------------Kuvalayapeeda----------------- */}
         
            {/* ----------------------------Navneeta Chora------------------- */}
            <div className="row pt-5" id="vasudev">
              <div className="col-lg-6 col-12 order-lg-1 order-2">
                <div className="mahaImg">
                  <img src={m5} alt="terrace" />
                </div>
              </div>
              <div className="col-lg-6 col-12 order-lg-2 order-3">
              <div className="mahaImg">
                <img src={m6} alt="banquet halls" />
              </div>
            </div>
              <div className="col-lg-6 col-12 tab-contents order-lg-3 order-2 pt-lg-5 pt-2" >
                <div className="tab-content">
                  <div role="tabpanel" className="tab-pane active" >
                    <h3 className="">5. Navneeta Chora</h3>

                    <p>
                    This frame depicts Lord Krishna playing Dahi—handi with other Gopalas. Krishna and his friends used to form human pyramids to break pots hung from the ceilings of neighbourhood houses, to steal curd and butter.
                    </p>
                  </div>
                </div>
              </div>
              <div
              className="col-lg-6 col-12 tab-contents order-lg-4 order-4 pt-lg-5 pt-2"
              id="kiran-bala-shagrithaya-hall"
            >
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className="">6. Dhenukasuar</h3>

                  <p>
                  Dhenukasura was a demon in the form of a wicked donkey who lived in Vrindavan, a sacred forest where Lord Krishna spent his childhood. One day, Lord Krishna and his friends decided to enter the forest to play. However, Dhenukasura, who was protecting the forest for his master, attacked them.
                  Lord Krishna effortlessly killed Dhenukasura by grabbing him by his hind legs and swinging him around. The demon was thrown into the air and struck a tree, instantly killing him. 
                  </p>
                </div>
              </div>
            </div>
            </div>
            {/* ------------------------------6.Dhenukasuar---------------- */}
           
            
            {/* ---------------------------Muddhu Krishna------------------- */}
            <div className="row pt-5">
            <div className="col-lg-6 col-12 order-lg-1 order-2">
              <div className="mahaImg">
                <img src={m7} alt="banquet halls" />
              </div>
            </div>
            <div className="col-lg-6 col-12 order-lg-2 order-3">
              <div className="mahaImg">
                <img src={m8} alt="banquet halls" />
              </div>
            </div>
            <div
              className="col-lg-6 col-12 tab-contents order-lg-3 order-2 pt-lg-5 pt-2"
              id="kiran-bala-shagrithaya-hall"
            >
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className="">7. Muddhu Krishna </h3>

                  <p>
                  This is the most beautiful depiction of Krishna as a playful and mischievous child.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 tab-contents order-lg-4 order-4 pt-lg-5 pt-2" id="panoramic-view">
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className="">8. Bakasura</h3>

                  <p>
                  Bakasura was a demon who took the form of a gigantic crane. Kamsa sent him to devour Krishna and his friends. However, Krishna easily defeated Bakasura by tearing apart his beak and thus ended his threat.
                  </p>
                </div>
              </div>
            </div>
          
              </div>
          
            {/* -----------------------------Bakasura--------------------- */}
           

            {/* ---------------------Yashoda Madilalli----------------------- */}
            <div className="row pt-5">
            <div className="col-lg-6 col-12 order-lg-1 order-2">
              <div className="mahaImg">
                <img src={m9} alt="terrace" />
              </div>
            </div>
            <div className="col-lg-6 col-12 order-lg-2 order-3">
              <div className="mahaImg">
                <img src={m10} alt="banquet halls" />
              </div>
            </div>
            <div
              className="col-lg-6 col-12 tab-contents order-lg-3 order-2 pt-lg-5 pt-2"
              id="kiran-bala-shagrithaya-hall"
            >
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className="">9. Yashoda Madilalli</h3>

                  <p>
                  Once, when Lord Krishna was a young child, he saw the glowing, shimmering moon in the night sky and became fascinated by it. He approached his mother Yashoda and asked her to bring him the moon as a plaything.

Yashoda, knowing that it was impossible to bring the moon down, decided to play along with Krishna. She said to him, "Alright, my dear Krishna. If you want the moon, then I will get it for you”, and shows it in the reflection in her palm.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-12 tab-contents order-lg-4 order-4 pt-lg-5 pt-2"
              id="kiran-bala-shagrithaya-hall"
            >
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className="">10. Shakatusara </h3>

                  <p>
                  Shakatasura was a demon who took the form of a cart (shakata) and terrorized the residents of Vrindavan.

One day, as Krishna and his friends were playing, they came across the cart demon, who attempted to harm them. But Krishna, with his divine powers, quickly realized the demon's intentions and decided to put an end to his mischief.
Krishna pushed the cart with such force that it shattered into pieces, destroying the demon within. Thus, Krishna earned the nickname "Shakatasura Mardana," which means the slayer of the cart demon.Krishna pushed the cart with such force that it shattered into pieces, destroying the demon within. Thus, Krishna earned the nickname "Shakatasura Mardana," which means the slayer of the cart demon.
                  </p>
                </div>
              </div>
            </div>
            </div>
            {/* ----------------------------Shakatusara---------------------- */}
           
            {/* -------------Navneet chora------------- */}
            <div className="row pt-5">
            <div className="col-lg-6 col-12 order-lg-1 order-2">
              <div className="mahaImg">
                <img src={m11} alt="terrace" />
              </div>
            </div>
            <div className="col-lg-6 col-12 order-lg-2 order-3">
              <div className="mahaImg">
                <img src={m12} alt="banquet halls" />
              </div>
            </div>
            <div className="col-lg-6 col-12 tab-contents order-lg-3 order-2 pt-lg-5 pt-2" id="panoramic-view">
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className="">11. Navneeta Chora</h3>

                  <p>
                  This frame depicts the lovely episode of Mother Yashoda trying to tie Krishna up with a rope as he would often steal butter from the homes of the villagers in Gokul. Despite using several ropes of different lengths, she found that each rope was mysteriously too short for him!
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-12 tab-contents order-lg-4 order-4 pt-lg-5 pt-2"
              id="kiran-bala-shagrithaya-hall"
            >
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className="">12. Keshi Samhari </h3>

                  <p>
                  Keshi is a demon who assumes the form of a huge horse, gallops at the speed of thought, wears the earth with his hooves and scatters celestial vehicles and clouds in the sky with his mane. His neighing terrifies the people. Krishna challenges Keshi to a duel, as the horse is creating havoc around Gokula. Keshi roars like a lion and charges towards Krishna, striking him with his hooves. Krishna catches hold of Keshi's two legs and tosses him to a great distance. 

Recovering from the fall, the agitated Keshi opens his mouth and attacks Krishna. As soon as Krishna thrusts his left arm into Keshi's mouth, all of Keshi's teeth fall. Krishna's arm expands, and Keshi chokes to death, as sweat flows from his body, his eyes roll, and he struggles kicking his feet. As Keshi falls lifeless on the ground, assuming his true demon form, the gods and Narada extol Krishna. 
                  </p>
                </div>
              </div>
            </div>
            </div>
            {/* --------------------Keshi ------------- */}
          
            {/* -------------Gopi stri vastra harana------------------------ */}
            <div className="row pt-5">
            <div className="col-lg-6 col-12 order-lg-1 order-2">
              <div className="mahaImg">
                <img src={m13} alt="terrace" />
              </div>
            </div>
            <div className="col-lg-6 col-12 order-lg-2 order-3">
              <div className="mahaImg">
                <img src={m14} alt="banquet halls" />
              </div>
            </div>
            <div className="col-lg-6 col-12 tab-contents order-lg-3 order-2 pt-lg-5 pt-2" id="panoramic-view">
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className=""> 13. Gopi stri vastra harana</h3>

                  <p>
                  This frame refers to an incident where Lord Krishna playfully stole the clothes of the Gopikas (cowherd women) while they were bathing in the Yamuna river. This event is depicted in various narratives and often celebrated as a playful and mischievous act of Lord Krishna, showcasing his divine love and connection with his devotees. The Gopikas' reaction to this incident is considered to be a display of their deep devotion and surrender to Lord Krishna.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-12 tab-contents order-lg-4 order-4 pt-lg-5 pt-2"
              id="kiran-bala-shagrithaya-hall"
            >
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className="">14. Putani Samhara </h3>

                  <p>
                  Putana, disguised as a beautiful woman, tried to deceive, and nurse baby Krishna with poisoned milk. Unbeknownst to her, Krishna, who possessed immense strength, grabbed her by the hair and pulled her closer, draining her of her life force and liberating her from her demonic existence.


                  </p>
                </div>
              </div>
            </div>
            </div>

{/* ----------------------------------14.Putani Samhara----------- */}
           
            {/* ------------15.Gopi Stree Peedana------------------------ */}
            <div className="row pt-5">
            <div className="col-lg-6 col-12 order-lg-1 order-2">
              <div className="mahaImg">
                <img src={m15} alt="terrace" />
              </div>
            </div>
            <div className="col-lg-6 col-12 order-lg-2 order-3">
              <div className="mahaImg">
                <img src={m16} alt="banquet halls" />
              </div>
            </div>
            <div className="col-lg-6 col-12 tab-contents order-lg-3 order-2 pt-lg-5 pt-2" id="panoramic-view">
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className=""> 15. Gopi Stree Peedana</h3>

                  <p>
                  Krishna used to lovingly pester and playfully irk the Gopikas of Gokul by stealing butter and curds and playing mischief.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-12 tab-contents order-lg-4 order-4 pt-lg-5 pt-2"
              id="kiran-bala-shagrithaya-hall"
            >
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className="">16. Dharitri Samrakshna </h3>

                  <p>
                  Mother Earth had become very sad due to the atrocities and sins of powerful and cruel demons. She was suffering a lot on seeing such evil creatures roaming on her. She takes the form of a cow and seeks divine help. Lord Krishna then saves her from this wretched menace during His incarnation.
                  </p>
                </div>
              </div>
            </div>
            </div>
            {/* ------------------16.Dharitri Samrakshna------------ */}
           
            {/* -------------17.Govardhana Giri.------------------------ */}
            <div className="row pt-5">
            <div className="col-lg-6 col-12 order-lg-1 order-2">
              <div className="mahaImg">
                <img src={m17} alt="terrace" />
              </div>
            </div>
            <div className="col-lg-6 col-12 order-lg-2 order-3">
              <div className="mahaImg">
                <img src={m18} alt="banquet halls" />
              </div>
            </div>
            <div className="col-lg-6 col-12 tab-contents order-lg-3 order-2 pt-lg-5 pt-2" id="panoramic-view">
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className=""> 17. Govardhana Giri</h3>

                  <p>
                  According to this story, the village of Vrindavan, where Lord Krishna grew up, was suffering from heavy rainfall and floods caused by the wrath of Indra, the god of rain and thunder.

Instead of seeking shelter from Indra's anger, Lord Krishna decided to protect the villagers and animals by lifting Govardhan Hill, a mountainous region, on his little finger. He asked all the villagers to seek shelter underneath the hill, providing them safety from the calamity.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-12 tab-contents order-lg-4 order-4 pt-lg-5 pt-2"
              id="kiran-bala-shagrithaya-hall"
            >
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className="">18. Kubja Shapa Vimochana </h3>

                  <p>
                  An intricate carving depicts the Kubja Shapa Vimochana story that recounts the liberation of Kubja, a hunchbacked woman, from a curse. Kubja, who was under a curse due to her past deeds is instantly transformed by Lord Krishna into a beautiful woman. 
                  </p>
                </div>
              </div>
            </div>
            </div>
           
            {/* -------------19.Krishna Sudama------------------------ */}
            <div className="row pt-5">
            <div className="col-lg-6 col-12 order-lg-1 order-2">
              <div className="mahaImg">
                <img src={m19} alt="terrace" />
              </div>
            </div>
            <div className="col-lg-6 col-12 order-lg-2 order-3">
              <div className="mahaImg">
                <img src={m20} alt="banquet halls" />
              </div>
            </div>
            <div className="col-lg-6 col-12 tab-contents order-lg-3 order-2 pt-lg-5 pt-2" id="panoramic-view">
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className="">19. Krishna Sudama</h3>

                  <p>
                  This frame captures the moral of the Krishna Sudama story. The moral of the many stories of the playful and mischievous Lord Krishna and his dear friend Sudama is the value of true companionship. And how friendship goes beyond one's financial or social status.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-12 tab-contents order-lg-4 order-4 pt-lg-5 pt-2"
              id="kiran-bala-shagrithaya-hall"
            >
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className="">20. Balabhadra Priya </h3>

                  <p>
                  There is a lovely depiction of Balarama, Krishna elder brother, feeding his Anuja Krishna.
                  </p>
                </div>
              </div>
            </div>
            </div>
            {/* ------------------20.Balabhadra Priya----------------- */}
          
            {/* -------------21.Mudhu Krishna ----------------------- */}
            <div className="row pt-5">
            <div className="col-lg-6 col-12 order-lg-1 order-2">
              <div className="mahaImg">
                <img src={m21} alt="terrace" />
              </div>
            </div>
            <div className="col-lg-6 col-12 order-lg-2 order-3">
              <div className="mahaImg">
                <img src={m22} alt="banquet halls" />
              </div>
            </div>
            <div className="col-lg-6 col-12 tab-contents order-lg-3 order-2 pt-lg-5 pt-2" id="panoramic-view">
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className=""> 21. Mudhu Krishna </h3>

                  <p>
                  This beautiful frame shows Krishna with butter in one hand and Laddu in the other.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-12 tab-contents order-lg-4 order-4 pt-lg-5 pt-2"
              id="kiran-bala-shagrithaya-hall"
            >
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className="">   22. Yamla-arjuna Banjane</h3>

                  <p>
               

While mother Yasoda was very busy with household affairs, the
Supreme Lord, Krsna, observed twin trees known as yamala-
arjuna, which in a former millennium had been the demigod
sons of Kuvera.
In their former birth, these two sons, known as Nalakuvara and
Manigriva, were extremely opulent and fortunate. A sage had
long ago imprisoned these two arrogant, drunken sons of
Kubera, Fulfilling the sage&#39;s prophecy, Krishna rolled the mortar
between the trunks and the tree split open to release the
youths.
                  </p>
                </div>
              </div>
            </div>
            </div>
            {/* ------------------------------------22.Kalinga Mardana-------------- */}
     
            {/* -------------23.Gopala Balakrishna------------------------ */}
            <div className="row pt-5">
            <div className="col-lg-6 col-12 order-lg-1 order-2">
              <div className="mahaImg">
                <img src={m23} alt="terrace" />
              </div>
            </div>
            <div className="col-lg-6 col-12 order-lg-2 order-3">
              <div className="mahaImg">
                <img src={m24} alt="banquet halls" />
              </div>
            </div>
            <div
              className="col-lg-6 col-12 tab-contents order-lg-3 order-2 pt-lg-5 pt-2"
              id="kiran-bala-shagrithaya-hall"
            >
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className="">23. Kalinga Mardana</h3>

                  <p>
                  This frame depicts the story of Lord Krishna's meeting with Kaliya, or Kalinga. Kaliya was a dreaded and poisonous serpent, who was driven away by Garuda from the banks of Ramanaka Dwipa. He then moved to Vrindavan, on the banks of Yamuna. Lord Krishna slays this poisonous snake to save the people of Vrindavan.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 tab-contents order-lg-4 order-4 pt-lg-5 pt-2" id="panoramic-view">
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className=""> 24. Gopala Balakrishna</h3>

                  <p>
                  This extremely lovely frame captures the mother Yashoda taking care of her child Krishna while milking her cow.
                  </p>
                </div>
              </div>
            </div>
          
            </div>
            {/* ------------------24.Navneeta Chora---------------------- */}
            
            {/* -------------25.Yashoda Krishna------------------------ */}
            <div className="row pt-5">
            <div className="col-lg-6 col-12 order-lg-1 order-2">
              <div className="mahaImg">
                <img src={m25} alt="terrace" />
              </div>
            </div>
            <div className="col-lg-6 col-12 order-lg-2 order-3">
              <div className="mahaImg">
                <img src={m26} alt="banquet halls" />
              </div>
            </div>
            <div
              className="col-lg-6 col-12 tab-contents order-lg-3 order-2 pt-lg-5 pt-2"
              id="kiran-bala-shagrithaya-hall"
            >
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className="">25. Navneeta Chora </h3>

                  <p>
                  The playful frame depicts the mischievous Krishna stealing butter even as the Gopikas are churning the buttermilk to get the butter.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 tab-contents order-lg-4 order-4 pt-lg-5 pt-2" id="panoramic-view">
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className=""> 26. Yashoda Krishna</h3>

                  <p>
                  Mother Yashoda very affectionately feeding her darling child Krishna.      </p>
                </div>
              </div>
            </div>
            </div>
            {/* ------------------26.Yamuna Vega Samhari--------------- */}
           
            {/* ------------------------------27.Yamuna----------------------- */}
            <div className="row pt-5">
            <div className="col-lg-6 col-12 order-lg-1 order-2">
              <div className="mahaImg">
                <img src={m27} alt="terrace" />
              </div>
            </div>
            <div className="col-lg-6 col-12 order-lg-2 order-3">
              <div className="mahaImg">
                <img src={m28} alt="terrace" />
              </div>
            </div>
            <div className="col-lg-6 col-12 tab-contents order-lg-3 order-2 pt-lg-5 pt-2" id="panoramic-view">
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className=""> 27. Yamuna Vega Samhari</h3>

                  <p>
                  On the stormy night of Krishna&#39;s birth, Vasudeva wrapped the
baby in a cloth and cradled Him in his arms. The river Yamuna,
which was usually turbulent, parted its waters and formed a
clear path for Vasudeva to walk on.</p><p>
As Vasudeva walked through the river, he encountered a series
of challenges. The river was swollen with rainwater, and the
current was strong. However, Vasudeva remained fearless,
knowing that Lord Krishna was with him. He carefully navigated
through the treacherous waters, holding Lord Krishna above his
head.

                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 tab-contents order-lg-4 order-4 pt-lg-5 pt-2" id="panoramic-view">
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className=""> 28. Bala Mukunda</h3>

                  <p>
                  Lord Krishna is often referred to as Bala Mukunda, which translates to "the child who grants liberation." This name represents the divine aspect of Krishna during His childhood. Bala Mukunda is depicted as an adorable, charming, and mischievous child who captivates the hearts of everyone around Him. He is depicted her shown sleeping on a leaf of a banyan tree, afloat on the surging waters of a final deluge signifying the end of Dwaparyuga.

                  </p>
                </div>
              </div>
            </div>
            </div>
            {/* -------------28.Bala Mukunda------------------------ */}
           
        
           
         
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Mahadwara