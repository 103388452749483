import React from "react";
import {Link} from 'react-router-dom';
import Footer from "../component/Footer";


const Errorpage = () => {
  return (
    <div>
    <section class="aboutInfoSection pt-5">
        <div class="container">
            <div class="aboutHomeHead">
              <h1 className="d-none">
             
              </h1>
              {/* <div className="bannerLogo">
        <img src={flute} alt=""/>
       </div> */}
                <h2 class="">
               404| Page not found
             
                </h2>
                
            </div>
        </div>
    </section>
    <section>
      <div className="container">
        <div className="row">
          <h2>Back To<span style={{color:'blue!important'}}><Link to="/"> Home</Link></span> </h2>
        </div>
      </div>
    </section>
    <Footer/>
    </div>
  );
};

export default Errorpage;
