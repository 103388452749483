import { React, useState,useLayoutEffect } from "react";
import "../CSS/Service.css";
import {Link} from "react-router-dom";
// ----------------services---------------------
import templecarving from "../Images/Temple/templecarvings.JPG";
import templedecor from "../Images/Temple/templedecor1.JPG";
import templesikh from "../Images/Temple/templesikh.JPG";
import templesitting from "../Images/Temple/templesitting-decor.JPG";
import templepolice from "../Images/Temple/templepolice.JPG";
import templesofa from "../Images/Temple/templesittingsofa.JPG";
import dine from "../Images/Temple/dinning.JPG";
import program from "../Images/Temple/cultural-program.JPG";
import meditation from "../Images/services/meditation.jpg"
import client from "../Images/WhatsApp Image 2023-12-28 at 2.38.02 PM.jpeg";
import client2 from "../Images/Screenshot (271).png";
import client3 from "../Images/Screenshot (272).png";
// ------------------halls----------------
import kiran  from "../Images/Temple/kiran-bala1.JPG";
import terrace from "../Images/Temple/terrace.JPG";
import janardhan from "../Images/Temple/halldecor.JPG"
import banq from "../Images/Temple/temple.jpeg";
import banq1 from "../Images/Temple/saraswati.jpeg";
import yoga from "../Images/services/yoga.jpg"
import welcome from "../Images/welcome.png";
// ------------icons---------------------
import hk from "../Images/elevator.png";
import cater from "../Images/buffet.png";
import electric from "../Images/parked-car.png";
import camera from "../Images/cctv (1).png";
import temple from "../Images/wedding-arch.png";
import fan from "../Images/air-conditioner.png";
import Footer from "../component/Footer";
// ---------------------gallery-------------------- 
// ------------------religious--------------------
import religious1 from "../Images/services/religious.jpeg";
import religious2 from "../Images/services/religious1.jpeg";
// import religious3 from "../Images/services/religious3.jpeg";
import religious4 from "../Images/services/gauseva.jpeg";
import religious5 from "../Images/services/religious_shani.jpeg";
import religious6 from "../Images/services/havan2.jpeg";
import durga from "../Images/gallery/IMG_7929.JPG";
// ------------classes--------------------------------
import class1 from "../Images/services/classes.jpeg";
import class2 from "../Images/services/classes1.jpeg";
import drama from "../Images/services/Dramatic.jpeg";
// -----------------performnces-----------------
import perform1 from "../Images/services/performance-dance.jpeg";
import perform2 from "../Images/services/dance.jpeg";
import corp4 from "../Images/live.webp";
import screening from "../Images/screening.jpg";
import karaoke from  "../Images/services/karaoke.jpg"
import music from "../Images/Temple/music-concert.jpeg";
// -------------------corporate events--------------------
import launch from '../Images/Product_Launch.webp';
////////////// social events //////////////////
import  festival from "../Images/Temple/ganpati pooja.jpg";
import dandiya from "../Images/dandiya2.jpg";
import  eve from "../Images/eve.jpg";
import exhib from "../Images/exhibition.jpg";
import school from "../Images/reunion.jpg";
import kitty from "../Images/kitty-party.png";
// -----------------Family Functions----------------

import wedding from "../Images/Temple/brideenter.JPG";
import wedding3 from "../Images/baby.jpg";
import engage from "../Images/engage.jpg";
import baby from "../Images/baby.jpg";
import bday from "../Images/birthday.webp";
import haldi from "../Images/haldi.jpg";
import thread from "../Images/thread-ceremony.jpg";
////////////// Corporate Events //////////////////
import seminar from "../Images/gallery/IMG_7179.JPG";
import workshop from "../Images/gallery/IMG_7193.jpg";
import conference from "../Images/gallery/IMG_7280.jpg";
import agm from "../Images/Annual-General-Meeting.webp";
import corporate from "../Images/CorporateMeetings.jpg";
import press from "../Images/CorporateMeetings.jpg";
// import Tabs from "./Tabs";
// import Items from "./Items";
// import GalleryData from '../component/GalleryData';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation } from "swiper";
import { Autoplay, Pagination } from "swiper/modules";
import {MetaTags} from "react-meta-tags";
const Service = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
 
    

  return (
    <div>
      {/* ========= SEO ========== */}

<MetaTags>
        <title>
        Banquet In Sion | Gokul Events
        </title>
        <meta name="title" title="Banquet In Sion | Gokul Events" />
        <meta
          name="description"
          content="Experience opulence at our elegant banquet in Sion. Gokul Events - where grand celebrations become timeless memories."
        />
       
        <meta
          property="og:title"
          content="Banquet In Sion | Gokul Events"
        />
        <meta
          property="og:description"
          content="Experience opulence at our elegant banquet in Sion. Gokul Events - where grand celebrations become timeless memories."
        />
        <meta
          name="keywords"
          content="Hall In Sion,
          Party Hall In Sion,
          Banquet in Sion,
          Wedding Banquet In Sion,
          Best Banquet In Sion,
          Party Banquet In Sion,
          Gokul Hall,
          auditorium In Sion,
          AC Hall In Sion,
          AC Wedding Halls In Sion,
          Roof Top Hall In Sion"
        />
        <link rel="canonical" href="https://gokulevents.in/banquet-in-sion" />
        <meta name="google-site-verification" content="VBB-V05nLFwZS9pwXxrINkZ79RHxEazT6XA_NDNapFA" />
      </MetaTags>

      {/* ======== SEO end ======= */}
      <section class="aboutInfoSection pt-5">
        <div class="container">
          <div class="aboutHomeHead">
            <h1 className="d-none">Banquet In Sion | Gokul Events</h1>
            {/* <div className="bannerLogo">
        <img src={flute} alt=""/>
       </div> */}
            <h2 class="">Premier Services</h2>
          </div>
        </div>
      </section>
      {/* -----------------------------------qualities------------------ */}
      <section className="service p-lg-5 ">
        <div className="container">
          <div className="service-inner">
            <div className="row">
              <div className="col-lg-6 col-md-8 col-12">
                <div className="plan-discription" data-aos="fade-right">
                  <h3>
                  Elevate Your Experience 
                    <br /> with Our Services{" "}
                  </h3>
                  <p>
                  With a legacy built upon years of experience, we stand as your trusted partner in crafting exceptional moments. Our journey through countless events has honed our expertise, refining our skills to meet the evolving needs of our clients. This reservoir of knowledge is now at your disposal, as we bring together the art of event planning and the science of execution to create seamless and unforgettable experiences.
                  </p>
                  <p>
                  From intimate gatherings to grand galas, our team's depth of experience ensures that every detail is meticulously attended to.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-4 col-12">
              <Swiper
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay, Pagination]}
                  pagination={{
                    clickable: true,
                  }}
                  className="mySwiper1"
                >
                  <SwiperSlide>
                    <div className="side-logo">
                      <img src={templecarving} alt="banquet halls" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="side-logo">
                      <img src={templedecor} alt="banquet halls" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="side-logo">
                      <img src={templesikh} alt="banquet halls" />
                    </div>
                  </SwiperSlide>
                    <SwiperSlide>
                    <div className="side-logo">
                      <img src={templesitting} alt="banquet halls" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="side-logo">
                      <img src={templepolice} alt="banquet halls" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="side-logo">
                      <img src={templesofa} alt="banquet halls" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="side-logo">
                      <img src={dine} alt="banquet halls" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="side-logo">
                      <img src={program} alt="banquet halls" />
                    </div>
                  </SwiperSlide>
                </Swiper>
               
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="homeSection section3 p-lg-5">
        <div className="container">
          <div className="homeSection ">
            <div className="row serviceList">
              <div className="col-lg-2 col-md-3 col-6 mt-2">
                <div className="servList">
                  <div className="serviceIcon">
                    <img src={fan} />
                  </div>
                  <h2>AC Halls</h2>
                  <h6>Centrally Airconditioned</h6>
                  <br />
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-6 mt-2">
                <div className="servList">
                  <div className="serviceIcon">
                    <img src={hk} />
                  </div>
                  <h2>Lifts</h2>
                  <h6>Four Passenger Lifts</h6>
                  <br />
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-6 mt-2">
                <div className="servList">
                  <div className="serviceIcon">
                    <img src={cater} />
                  </div>
                  <h2>Catering</h2>
                  <h6>Pure Vegetarian</h6>
                  <br />
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-6 mt-2">
                <div className="servList">
                  <div className="serviceIcon">
                    <img src={electric} />
                  </div>
                  <h2> Car Parking </h2>
                  <h6> Valet Parking (60 cars)</h6>
                  <br />
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-6 mt-2">
                <div className="servList">
                  <div className="serviceIcon">
                    <img src={camera} />
                  </div>
                  <h2>Security</h2>
                  <h6>CCTV camera</h6>
                  <br />
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-6 mt-2">
                <div className="servList">
                  <div className="serviceIcon">
                    <img src={temple} />
                  </div>
                  <h2>Decoration </h2>
                  <h6>Customized experience</h6>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr></hr>

          {/* ///////////////////////////////// section 2 ////////////////////////////////// */}

          {/* <div className='Gallery-Section-2'>
                <div className='Gallery-Card'>

                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Tabs filterCategory={filterCategory} tabsData={tabsData} />
                                <Items data={data} />
                            </div>
                        </div>

                    </div>
                </div>
            </div> */}
      <section className="serviceSection">
        <div className="container">
          <div className="row">
            <div className="aboutDesc">
              <h2 className="plan-heading">Diverse Range of Events</h2>
            </div>
           
            <div className="col-lg-4 col-md-6 col-12">
              <Swiper
                autoplay={{
                  delay: 3500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination]}
                pagination={{
                  clickable: true,
                }}
                className="mySwiper1"
              >
                  <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                      <a href="#">
                        <img src={workshop} alt="event" />
                      </a>
                    </div>
                    <h4>
                      Workshop
                    </h4>
                  </div>
                </SwiperSlide>
                  <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                      <a href="#">
                        <img src={conference} alt="event" />
                      </a>
                    </div>
                    <h4>
                      Conferences
                    </h4>
                  </div>
                </SwiperSlide>
                  <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                      
                        <img src={seminar} alt="event" />
                   
                    </div>
                    <h4>
                     Seminars
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                      
                        <img src={corporate} alt="event" />
                      
                    </div>
                    <h4>
                      Corporate Meetings
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                      
                        <img src={agm} alt="event" />
                    
                    </div>
                    <h4>
                      AGM's
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                      
                        <img src={launch} alt="event" />
                      
                    </div>
                    <h4>
                      Product Launches
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                      
                        <img src={press} alt="event" />
                      
                    </div>
                    <h4>
                      Press Conference
                    </h4>
                  </div>
                </SwiperSlide>
                <div className="servtext mt-lg-2">
                <h5>Corporate Events</h5>
              </div>
              </Swiper>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
            <Swiper
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination]}
                pagination={{
                  clickable: true,
                }}
                className="mySwiper1"
              >
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                      
                        <img src={wedding} alt="event" />
                      
                    </div>
                    <h4>
                    Weddding
                                        </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                      
                        <img src={baby} alt="event" />
                      
                    </div>
                    <h4>
                      Baby Shower
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                      
                        <img src={bday} alt="event" />
                      
                    </div>
                    <h4>
                      Birthday
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                      
                        <img src={engage} alt="event" />
                      
                    </div>
                    <h4>
                      Engagement
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                
                        <img src={thread} alt="event" />
                  
                    </div>
                    <h4>
                    Thread Ceremony
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                    
                        <img src={engage} alt="event" />
                      
                    </div>
                    <h4>
                      Reception
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                      
                        <img src={haldi} alt="event" />
                      
                    </div>
                    <h4>
                      Haldi
                    </h4>
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className="servtext mt-lg-2">
                <h5>Family Functions</h5>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
            <Swiper
                autoplay={{
                  delay: 4500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination]}
                pagination={{
                  clickable: true,
                }}
                className="mySwiper1"
              >
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                    
                        <img src={perform1} alt="event" />
                      
                    </div>
                    <h4>
                      Dance
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                    
                        <img src={music} alt="event" />
                      
                    </div>
                    <h4>
                      Kutcheries
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                    
                        <img src={screening} alt="event" />
                      
                    </div>
                    <h4>
              Private Screening
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                      
                        <img src={corp4} alt="event" />
                    
                    </div>
                    <h4>
                      Live Streaming
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                
                        <img src={karaoke} alt="event" />
                      
                    </div>
                    <h4>
                      Karaoke
                    </h4>
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className="servtext mt-lg-2">
                <h5>Performances</h5>
              </div>
            </div>
         
            <div className="col-lg-4 col-md-6 col-12">
            <Swiper
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination]}
                pagination={{
                  clickable: true,
                }}
                className="mySwiper1"
              >
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                      
                        <img src={dandiya} alt="event" />
                      
                    </div>
                    <h4>
                      Dandiya
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                      
                        <img src={eve} alt="event" />
                      
                    </div>
                    <h4>
                      Evening Parties
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                    
                        <img src={festival} alt="event" />
                      
                    </div>
                    <h4>
                    Festival Celebration
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                      <a href="#">
                        <img src={kitty} alt="event" />
                      </a>
                    </div>
                    <h4>
                      Kitty Parties
                    </h4>
                  </div>
                </SwiperSlide>
               
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                      
                        <img src={school} alt="event" />
                      
                    </div>
                    <h4>
                    School Reunion
                    </h4>
                  </div>
                </SwiperSlide>
              
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                      
                        <img src={exhib} alt="event" />
                      
                    </div>
                    <h4>
                    Exhibitions
                    </h4>
                  </div>
                </SwiperSlide>
                <div className="servtext mt-lg-2">
                <h5>Social Events</h5>
              </div>
              </Swiper>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
            <Swiper
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination]}
                pagination={{
                  clickable: true,
                }}
                className="mySwiper1"
              >
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                      
                        <img src={perform2} alt="event" />
                      
                    </div>
                    <h4>
                    Language
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                    
                        <img src={class2} alt="event" />
                      
                    </div>
                    <h4>
                      Summer Camp
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                      
                        <img src={class1} alt="event" />
                    
                    </div>
                    <h4>
                  Hobby Class
                    </h4>
                  </div>
                </SwiperSlide>
               
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                      
                        <img src={yoga} alt="event" />
                      
                    </div>
                    <h4>
                      Yoga
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                      
                        <img src={meditation} alt="event" />
                      
                    </div>
                    <h4>
                      Mediation
                    </h4>
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className="servtext mt-lg-2">
                <h5>Classes</h5>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
            <Swiper
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination]}
                pagination={{
                  clickable: true,
                }}
                className="mySwiper1"
              >
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                      <a href="#">
                        <img src={religious5} alt="event" />
                      </a>
                    </div>
                    <h4>
                      <a href="#">Shani Puja</a>
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                      
                        <img src={religious1} alt="event" />
                      
                    </div>
                    <h4>
                      Satnarayan Puja
                    </h4>
                  </div>

                 
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                  
                        <img src={religious6} alt="event" />
                      
                    </div>
                    <h4>
                      All type Havans
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                    
                        <img src={durga} alt="event" />
                
                    </div>
                    <h4>
                      Durga Namaskara
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                      
                        <img src={religious6} alt="event" />
                      
                    </div>
                    <h4>
                      Griha Shanti
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                      
                        <img src={religious4} alt="event" />
                      
                    </div>
                    <h4>
                      Gau Seva
                    </h4>
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className="servtext mt-lg-2">
                <h5>Religious</h5>
              </div>
            </div>
          
          </div>
        </div>
      </section>

      <hr></hr>

      {/* -----------------------------instagram Testimonials-------------- */}
      <section className="package-area two mt-65 bg-pink">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="package-img three">
                <Link to="https://www.instagram.com/reel/C1ToIfgLHTR/?utm_source=ig_embed&amp;utm_campaign=loading">
                <img src={client} alt="package" />
                </Link>
              </div>
            </div>
            <div className="col-md-8 col-sm-8 col-xs-12">
              <div className="package-content four three pt-160">
                <h2>Testimonial </h2>
                <h4 style={{textAlign:"center", color:"grey",fontSize:20, fontFamily:"sans-serif"}}>" Mr. Jitin, the official spokesperson of the Bharatiya Janata Party (BJP), paid a visit to our temple, and his positive words left us feeling incredibly encouraged. "</h4>
                <h3>Click Image for video</h3>
              
              </div>
            </div>
          </div>
          <hr/>
          <div className="row mt-5">
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="package-img three">
                <Link to="https://www.instagram.com/reel/C3ouwaPgOcl/?utm_source=ig_embed&amp;utm_campaign=loading">
                <img src={client2} alt="package" />
                </Link>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="package-img three">
                <Link to="https://www.instagram.com/reel/C3mXsNoqP1O/?utm_source=ig_embed&amp;utm_campaign=loading">
                <img src={client3} alt="package" />
                </Link>
              </div>
            </div>

          </div>
        </div>
      </section>
{/* --------------------------halls description----------------------- */}
<hr/>
<section id="whatWeDid" className=" contentRowPad">
        <div className="container">
          <div className="row" >
            <div className="col-md-6" id="gokulhall">
              <div className="banqImg">
                <img src={banq} alt="banquet halls"/>
              </div>
            </div>
            <div className="col-md-6 tab-contents" >
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" >
                  <h4>Ground Floor</h4>
                  <h3>Dr.Suresh Rao Gokul Hall</h3><p className="d-none">gokulhall</p>
<p>
This place has one of the finest work in wood carving, depicting the life of Lord Krishna, this combined with warm ceiling lights creates a peaceful and serene ambience that makes everyone feel welcomed. The Sanctum Sanctorum of Lord Gopalakrishna creates an auspicious vibe, perfect for weddings, satsangs and other ceremonies. This exquisite ambience can hold up to 175- 200 guests.
                  </p>
                  
                </div>
              </div>
            </div>

            <div className="col-md-6 tab-contents" id="saraswati-auditorium">
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h4>Second Floor</h4>
                  <h3>SARASWATHI AUDITORIUM</h3>

                  <p>
                  Saraswathi Auditorium, this air conditioned space has an inbuilt state of the art sound and light system. This stunning and elegant auditorium can comfortably seat 175 people. It aims to promote arts and culture along with giving a platform for artists of every genre. The stunning ambience is a marvellous place to hold musical programs and corporate functions.    </p>
               
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="banqImg">
                <img src={banq1} alt="banquet halls"/>
              </div>
</div>
              <div className="col-md-6" id="janardhan-ac-hall">
                <div className="banqImg">
                  <img src={janardhan} alt="banquet halls"/>
                </div>
              </div>
              <div className="col-md-6 tab-contents">
                <div className="tab-content">
                  <div role="tabpanel" className="tab-pane active" id="wwd2018">
                    <h4>Fourth Floor</h4>

                    <h3>M.K. Janardhan  Hall</h3>

                    <p>
                    M.K. Janardhan Hall,is the perfect venue for wedding functions and
                     other social gathering. This hall can easily fit in 225-250 guests. 
                     This hall is also suitable for corporate gatherings, 
                     meetings like AGM and Workshops. The in house décor team will 
                     gracefully charm you with their décor skills.   </p>
                    
                  </div>
                </div>
              </div>

              <div className="col-md-6 tab-contents" id="kiran-bala-shagrithaya-hall">
                <div className="tab-content">
                  <div role="tabpanel" className="tab-pane active" id="wwd2018">
                    <h4>Fifth Floor</h4>
                    <h3>Kiran Bala Shagrithaya Hall</h3>

                    <p>
                    Kiran Bala Shagrithaya Hall is an AC Wedding hall  which can fit in 225-250 guests. This elegant  place is perfect for wedding reception, engagements and more. The décor team will enhance the beauty of the hall with their expertise. This hall is well equipped with all the amenities and the facilities that are ideal for hosting celebrations and corporate events. </p>
                  
                  </div>
                </div>
              </div>
              <div className="col-md-6" >
                <div className="banqImg">
                  <img src={kiran} alt="banquet halls"/>
                </div>
              </div>
            
          <div className="col-md-6">
<div className='banqImg'>
    <img src={terrace} alt="terrace"/>
</div>
         
      </div>
      <div className="col-md-6 tab-contents" id="panoramic-view">
                <div className="tab-content">
                  <div role="tabpanel" className="tab-pane active" id="wwd2018">
                    <h4>Eighth Floor</h4>
                    <h3>Terrace/Roof top</h3>

                    <p>Terrace Garden is a Panoramic View of Mumbai. Terrace Garden can accommodate 300 to 350 guests with an option of hosting live buffet dinners. This is an ideal place for having open air functions. The onsite decorators will beautifully enhance the venue according to the theme or setting of your event and make your event forever unforgettable!   </p>
                    
                  </div>
                </div>
              </div>
              </div>
          </div>
      </section>
<hr/>
      <section className="package-area two mt-65 bg-pink">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="package-img two">
                <img src={welcome} alt="package" />
              </div>
            </div>
            <div className="col-md-8 col-sm-8 col-xs-12">
              <div className="package-content four three pt-160">
                <h4>We are here to make your occasion memorable and meaningful  </h4>
                <h2> Reach out to Gokul Hall </h2>
                <h3>call at &nbsp;8591732652</h3>
              
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr/>
      <Footer />
    </div>
  );
};

export default Service;
