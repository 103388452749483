import React from 'react';
import "../CSS/Tabs.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
// import { Navigation } from "swiper/react";
import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation } from "swiper";
// import { Autoplay, Pagination } from "swiper/modules";
// import Zoom from 'react-reveal/Zoom';
const Tabs = ({ filterCategory, tabsData }) => {


    return (
        <>
            <div className='Tabs-container'>
                {/* <Zoom right cascade> */}
                <Swiper
                                    
                                    // navigation={true}
                                    breakpoints={{
                                        200: {
                                            slidesPerView: 2,
                                            spaceBetween:0,
                                          },
                                      570: {
                                        slidesPerView: 4,
                                        spaceBetween: 0,
                                      },
                                      640: {
                                        slidesPerView: 3,
                                        spaceBetween: 10,
                                      },
                                      768: {
                                        slidesPerView: 3,
                                        spaceBetween: 10,
                                      },
                                      1024: {
                                        slidesPerView: 6,
                                        spaceBetween: 0,
                                      },
                                    }}
                                    // modules={[Navigation]}
                                  
                                    pagination={{
                                      clickable: true,
                                    }}
                                    className="swiper1"
                                  >
                    <div className="">
                        {
                            tabsData.map((category, index) => {
                                return (
                                   
                                    <SwiperSlide>
                                    <button type="button" className="btn btn-outline-primary  text-capitalize ButtonG" onClick={() => filterCategory(category)} key={index}>{category}</button>
                                    </SwiperSlide>
                                   
                                  
                                )
                            })
                        }
                    </div>
                    </Swiper>
                {/* </Zoom> */}
            </div>
        </>
    )
}

export default Tabs