import React from 'react'
import "../CSS/Footer.css";
import logo from "../Images/logo__2_-removebg-preview.png";
import { Link } from "react-router-dom";
import flute from "../Images/flutelogo-removebg-preview.png"
const Footer = () => {
  return (
    <div>
        <>
        <footer className="footer-section">
  <div className="container">
    <div className="footer-cta pt-5 pb-5">
      <div className="row">
        <div className="col-xl-4 col-md-4 mb-30">
          <div className="single-cta">
            <i className="fa fa-map-marker" />
            <div className="cta-text">
              <h4>Find us</h4>
              <span> Gokul, Plot no. 273, Gokul Marg, Sion (East), Mumbai-400022</span>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-4 mb-30">
          <div className="single-cta">
            <i className="fa fa-phone" />
            <div className="cta-text">
              <h4>Call us</h4>
              <span> 8591732652 / 8591732651 </span>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-4 mb-30">
          <div className="single-cta">
            <i className="fa fa-envelope-open" />
            <div className="cta-text">
              <h4>Mail us</h4>
              <span>gokulevents@bskba.com</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-content pt-5 pb-2">
      <div className="row">
        <div className="col-xl-4 col-lg-4 col-md-4 col-6 mb-50">
          {/* <div className="footer-widget"> */}
            <div className="footer-logo">
              <Link to="/">
                <img
                  src={logo}
                  className="img-fluid"
                  alt="logo"
                />
              </Link>
            </div>
            {/* <div className="footer-text">
              <p>
                Shree Krishna Digital is a fully fledged Digital Marketing
                Agency. Inaugurated in the year 2018 and located in Mumbai,
                Shree Krishna Digital, supports the concept of Digital India.
              </p>
            </div> */}
            {/* <div className="footer-social-icon">
              <span>Follow us</span>
              <a href="https://www.facebook.com/search/top?q=shree%20krishna%20digital%20marketing">
                <i className="fa fa-facebook facebook-bg" id=""/>
              </a>
              <a href="https://www.linkedin.com/company/shree-krishna-digital-marketing/">
                <i className="fa fa-linkedin twitter-bg" />
              </a>
              <a href="https://www.instagram.com/shree.krishnadigitalmarketing/?igshid=YmMyMTA2M2Y%3D">
                <i className="fa fa-instagram google-bg" />
              </a>
            </div> */}
          {/* </div> */}
        </div>
        <div className="col-xl-4 col-lg-4 col-md-5 col-12 mb-30 order-lg-2 order-md-2 order-3">
          <div className="footer-widget">
            <div className="footer-widget-heading">
              <h3>Useful Links</h3>
            </div>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
             
             
              <li>
              <Link to="/banquet-in-sion">Services</Link>
              </li>
              <li>
              <Link to="/Seva">Seva</Link>
              </li>
              <li>
              <Link to="/party-hall-in-sion">About Us</Link>
              </li>
              <li>
              <Link to="/top-hall-sion">Gallery</Link>
              </li>
              <li>
                <Link to="/party-banquet-in-sion">Contact us</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-3 col-6 order-lg-3 order-md-3 order-2">
          <div className="footer-widget">
            <div className="footer-widget-heading">
              <h3>
              <div className="bannerLogo ">
                <Link to="/">
        <img src={flute} alt=""/></Link>
       </div>
              </h3>
              
            </div>
            <div className="footer-text mb-25">
              {/* <p>
              Gokul, Plot no. 273, Gokul Marg, Sion (East)-Mumbai, 400022
              </p> */}
              
            </div>
            {/* <div className="subscribe-form">
              <form action="#">
                <input type="text" placeholder="Email Address" />
                <button>
                  <i className="fa fa-paper-plane" />
                </button>
              </form>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="copyright-area">
    <div className="container">
      <div className="row">
        <div className="col-xl-6 col-lg-6 text-center text-lg-left">
          <div className="copyright-text">
            <p>
              Copyright ©  Gokul Temple 2023, All Right Reserved{" "}<br/>
              Developed by  <a href="https://skdm.in">Shree Krishna Digital Marketing</a>
            </p>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
          {/* <div className="footer-menu">
            <ul>
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <a href="#">Terms</a>
              </li>
              <li>
                <a href="#">Privacy</a>
              </li>
              <li>
                <a href="#">Policy</a>
              </li>
              <li>
                <a href="#">Contact</a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  </div>
</footer>

</>

    </div>
  )
}

export default Footer