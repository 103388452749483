
// import BedRoom06 from "../Images/Dashavatara/2 Kurma avatar.JPG"
import Matsya  from "../Images/Dashavatara/1 Matsya avatar.JPG";
import Kurma from "../Images/Dashavatara/2 Kurma avatar.JPG";
import Varaha from "../Images/Dashavatara/3 Varaha avatar.JPG"
import Narshima from "../Images/Dashavatara/4 Narasimha avatar.JPG";
import Vamana from "../Images/Dashavatara/5 Vamana avatar.JPG";
import Parshurama from "../Images/Dashavatara/7 Rama avatar.JPG";
import Rama from "../Images/Dashavatara/6 Parashurama avatar.JPG";
import Krishna from "../Images/Dashavatara/8 Krishna avatar.JPG";
import Budha from "../Images/Dashavatara/9 Buddha avatar.JPG";
import Kalki from "../Images/Dashavatara/10 Kalki avatar.JPG";
const Avatardata = [
    {
      id: 1,
      Category:"Corporate Functions",
      Avatarname:"Matsya Avatar",
        Avtardsc:"Matsya avatar was taken by Lord Vishnu during the Satya Yuga when a demon named Hayagriva stole the Vedas.",
        AImage:Matsya,
    },
    {
      id: 2,
      Avatarname:"Kurma Avatar",
        Avtardsc:"In Kurma avatar, Lord Vishnu manifested as a giant tortoise during the churning of the cosmic ocean.",
        AImage:Kurma,
    },
    {
      id: 3,
      Avatarname:"Varaha Avatar",
      Avtardsc:"Varaha avatar depicts Lord Vishnu taking the form of a boar to rescue the earth goddess, Bhudevi, from the demon Hiranyaksha.",
      AImage:Varaha,
    },
    {
      id: 4,
      Avatarname:"Narasimha Avatar",
      Avtardsc:"Lord Vishnu appeared as a half-lion, half-human creature to protect his devotee, Prahlada, from his demon father, Hiranyakashipu.",
      AImage:Narshima,
    },
    {
      id: 5,
      Avatarname:"Vamana Avatar",
      Avtardsc:"Lord Vishnu incarnated as a dwarf Brahmin named Vamana to teach the demon king Mahabali a lesson in humility.",
      AImage:Vamana,
  
    },
    {
      id:6,
      Avatarname:"Parashurama Avatar",
      Avtardsc:"Parashurama is the warrior-sage avatar of Lord Vishnu. He was born to rid the earth of corrupt and tyrannical rulers",
      AImage:Parshurama,
    },
    
    {
      id: 7,
      Avatarname:"Rama Avatar",
        Avtardsc:"Lord Vishnu incarnated as Rama to destroy the demon king Ravana and restore dharma (righteousness) in the world.",
        Avtarshrtddesc:"",
        AImage:Rama,
    },
    {
        id: 8,
        Avatarname:"Krishna Avatar",
        Avtardsc:"one of the most beloved and worshipped avatars of Lord Vishnu, appeared in the Dvapara Yuga. ",
        AImage:Krishna,
    },
    {
        id: 9,
        Avatarname:"Buddha Avatar",
        Avtardsc:"Lord Vishnu took the form of Buddha to teach the path of non-violence, compassion, and spiritual enlightenment.",
        AImage:Budha,
    },
    {
      id: 10,
      Avatarname:"Kalki Avatar",
      Avtardsc:"Kali Yuga (the current age of darkness), riding a white horse to destroy evil forces and restore righteousness, thus beginning a new cycle.",
      AImage:Kalki,
    },
];
export default Avatardata;