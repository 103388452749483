import { React, useLayoutEffect, useState } from "react";
import TextToSpeech from "../component/TextToSpeech";
import { useParams } from "react-router-dom";
import Matsya from "../Images/Dashavatara/1 Matsya avatar.JPG";
import Footer from "../component/Footer";
import Kurma from "../Images/Dashavatara/2 Kurma avatar.JPG";
import Varaha from "../Images/Dashavatara/3 Varaha avatar.JPG";
import Narshima from "../Images/Dashavatara/4 Narasimha avatar.JPG";
import Vamana from "../Images/Dashavatara/5 Vamana avatar.JPG";
import Parshurama from "../Images/Dashavatara/7 Rama avatar.JPG";
import Rama from "../Images/Dashavatara/6 Parashurama avatar.JPG";
import Krishna from "../Images/Dashavatara/8 Krishna avatar.JPG";
import Budha from "../Images/Dashavatara/9 Buddha avatar.JPG";
import Kalki from "../Images/Dashavatara/10 Kalki avatar.JPG";

const Avatar = () => {
  const [language, setLanguage] = useState("english");
  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  const { name } = useParams();
  const paragraphEnglish =
    "Matsya avatar was taken by Lord Vishnu during the Satya Yuga when a demon named Hayagriva stole the Vedas. Lord Vishnu appeared as a fish to rescue the Vedas and humanity from the great flood. When this Solar system was full of gas which then turned into water, the first life that emerged was of the FISH CLAN that could live in water. This dimension of the Lord is MATSYAVATARA. Lord Brahma was the creation of Vishnu. He was vested with the task of creating life in the Universe. From the ear wax of Lord Vishnu two demons named MADHU and KAITABHA were born. Strong and intelligent but wicked as they were having born from the dirt, they stole away Brahma’s knowledge. Brahma had lost his knowledge and power of creation and felt his helplessness. He prayed to Lord Vishnu for help. Then Lord Vishnu took the form of MATSYA (a large fish), battled the demons through water, killed them and restored the Vedas to Lord Brahman. Later, the World faced annihilation with a fierce deluge (Pralaya). But before that event, Manu had collected samples of all species including humans, plants, and material in a large ship and the MATSYA steered the ship to a safe place in the Himalayas. The King then called MANU, could replenish the Earth over a period, with the life force. Matsyavatar symbolizes the first life force when there was only water and no earth.";
  const paragraphHindi =
    "matsy avataar bhagavaan vishnu ne satyayug ke dauraan tab liya tha jab hayagreev naamak raakshas ne vedon ko chura liya tha. vedon aur maanavata ko bheeshan baadh se bachaane ke lie bhagavaan vishnu machhalee ke roop mein prakat hue.jab yah sauramandal gais se bhara hua tha, jo baad mein paanee mein badal gaya, to sabase pahale jo jeevan ubhara vah machhalee samooh ka tha jo paanee mein rah sakata tha. bhagavaan ka yah aayaam matsyaavataar hai.bhagavaan brahma vishnu kee rachana the. unhen brahmaand mein jeevan banaane ka kaary saumpa gaya tha. bhagavaan vishnu ke kaan ke mail se madhu aur kaitabh naamak do raakshas paida hue. balavaan aur buddhimaan lekin dusht, kyonki ve mittee se paida hue the, unhonne brahma ka gyaan chura liya.brahma ne apana gyaan aur srjan kee shakti kho dee thee aur unhen apanee asahaayata mahasoos huee. unhonne bhagavaan vishnu se madad kee praarthana kee. tab bhagavaan vishnu ne matsy (ek badee machhalee) ka roop dhaaran kiya, paanee ke maadhyam se raakshason se yuddh kiya, unhen maar daala aur vedon ko bhagavaan braahman ko punarsthaapit kiya baad mein, vishv ko bheeshan jalapralay (pralay) se vinaash ka saamana karana pada. lekin us ghatana se pahale, manu ne ek bade jahaaj mein manushyon, paudhon aur saamagree sahit sabhee prajaatiyon ke namoone ekatr kie the aur matsy ne jahaaj ko himaalay mein ek surakshit sthaan par le jaaya tha. raaja ne tab manu ko bulaaya, jo jeevan shakti ke saath, ek avadhi mein prthvee ko phir se bhar sakata tha. matsyaavataar pahalee jeevan shakti ka prateek hai jab keval jal tha aur prthvee nahin thee.";
  // console.log(FData.Avatarname);

  console.log(language);
  return (
    <div>
      <section className="aboutInfoSection pt-5">
        <div className="container">
          <div className="aboutHomeHead">
            <div className="bannerLogo">
              <img src="" alt="" />
            </div>
            <h2>Dashavatar </h2>
          </div>
        </div>
      </section>
      {/* -----------------Avtar  details------------- */}
      <section id="wwd2018" className="avatarSection">
        <div className="container">
          {name === "Matsya Avatar" ? (
            <div className="row">
              <div className="col-md-4 tab-contents"></div>
              <div className="col-md-4" id="">
                <div className="banqImg">
                  <img src={Matsya} alt="" />
                </div>
              </div>

              <div className="col-md-4 tab-contents"></div>
              <div className="col-md-1"></div>
              <div className="col-md-10 tab-contents">
                <div className="tab-content">
                  <div role="tabpanel" className="tab-pane active">
                    <h4>Matsya Avatar </h4>

                    {/* <div className="d-flex align-items-center justify-content-center">
                      <select
                      style={{maxWidth:"200px", margin:"0 20px"}}
                        className="form-select"
                        onChange={handleLanguageChange}
                      >
                        <option value="english">English</option>
                        <option value="hindi">Hindi</option>
                      </select>
                      <TextToSpeech
                        text={
                          language === "english"
                            ? paragraphEnglish
                            : paragraphHindi
                        }
                      />
                    </div> */}
                    <p>
                      Matsya avatar was taken by Lord Vishnu during the Satya
                      Yuga when a demon named Hayagriva stole the Vedas. Lord
                      Vishnu appeared as a fish to rescue the Vedas and humanity
                      from the great flood.
                    </p>

                    <p>
                      When this Solar system was full of gas which then turned
                      into water, the first life that emerged was of the FISH
                      CLAN that could live in water. This dimension of the Lord
                      is MATSYAVATARA.
                    </p>

                    <p>
                      Lord Brahma was the creation of Vishnu. He was vested with
                      the task of creating life in the Universe. From the ear
                      wax of Lord Vishnu two demons named MADHU and KAITABHA
                      were born. Strong and intelligent but wicked as they were
                      having born from the dirt, they stole away Brahma’s
                      knowledge.
                    </p>

                    <p>
                      Brahma had lost his knowledge and power of creation and
                      felt his helplessness. He prayed to Lord Vishnu for help.
                      Then Lord Vishnu took the form of MATSYA (a large fish),
                      battled the demons through water, killed them and restored
                      the Vedas to Lord Brahman.
                    </p>

                    <p>
                      Later, the World faced annihilation with a fierce deluge
                      (Pralaya). But before that event, Manu had collected
                      samples of all species including humans, plants, and
                      material in a large ship and the MATSYA steered the ship
                      to a safe place in the Himalayas. The King then called
                      MANU, could replenish the Earth over a period, with the
                      life force. Matsyavatar symbolizes the first life force
                      when there was only water and no earth.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* ------------------------------kurma Avatar----------- */}
          {name === "Kurma Avatar" ? (
            <div className="row">
              <div className="col-md-4 tab-contents"></div>

              <div className="col-md-4" id="">
                <div className="banqImg">
                  <img src={Kurma} alt="" />
                </div>
              </div>
              <div className="col-md-4 tab-contents"></div>
              <div className="col-md-1"></div>
              <div className="col-md-10 tab-contents">
                <div className="tab-content">
                  <div role="tabpanel" className="tab-pane active">
                    <h4>Kurma Avatar</h4>

                    <p>
                      In Kurma avatar, Lord Vishnu manifested as a giant
                      tortoise during the churning of the cosmic ocean. He
                      provided support by becoming a base for the mount Mandara,
                      allowing gods and demons to churn the ocean and obtain the
                      nectar of immortality.
                    </p>

                    <p>
                      Sage Kashyap had two wives, one, Diti and the other Aditi.
                      Sons of Diti were Asuras with Rajasic attributes. Suras or
                      Deities were the children of Aditi who were with Satvik
                      attributes.
                    </p>

                    <p>
                      Once the deities and the Asuras decided to churn the Milky
                      Ocean (Ksheera sagara) to obtain Amruta. They made Mandara
                      mountain the churning stick and Vasuki snake a rope to
                      churn the ocean. Mandara started slipping away into water
                      due to which churning became difficult. Lord Vishnu then
                      took avatar as a TURTLE or TORTOISE, took Mandara on its
                      back and provided a base for the mountain to stay for
                      churning. The Deities and Asuras could now successfully
                      churn the ocean, but a quarrel arose between them on claim
                      for amruta, drinking of which would make them immortal.
                    </p>

                    <p>
                      The Lord then took the form of an enchanting, beautiful
                      girl MOHINI and induced them to give the vessel of amrut
                      to her to properly distribute it to both deities and
                      asuras. She tricked and succeeded in distributing amruta
                      only to the deities as the Lord knew that such a virtuous
                      thing should not be given to unworthy persons who could
                      not use but abuse its application.
                    </p>

                    <p>
                      Kurmavatar symbolizes the second dimension of life force,
                      amphibians emerging as the life force.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* ------------------------Varaha Avatar----------------------- */}
          {name === "Varaha Avatar" ? (
            <div className="row">
              <div className="col-md-4 col-12" id=""></div>
              <div className="col-md-4" id="">
                <div className="banqImg">
                  <img src={Varaha} alt="" />
                </div>
              </div>
              <div className="col-md-4" id=""></div>
              <div className="col-md-1" id=""></div>
              <div className="col-md-10 tab-contents">
                <div className="tab-content">
                  <div role="tabpanel" className="tab-pane active">
                    <h4>Varaha Avatar</h4>

                    <p>
                      Varaha avatar depicts Lord Vishnu taking the form of a
                      boar to rescue the earth goddess, Bhudevi, from the demon
                      Hiranyaksha, who had dragged her into the depths of the
                      cosmic ocean.
                    </p>

                    <p>
                      There occurred a great deluge in which the Mother Earth
                      drowned deep into the Waters. People say that Hiranyaksha
                      stole into waters. The Creator Lord Brahman was meditating
                      on how the Mother Earth could be salvaged. Then through
                      his nostrils, came a creature that fell into the Seas and
                      grew huge to form of a White Wild Boar [Sweta Varaha]. It
                      delved deep into the waters and took the Earth on its
                      tusk-like teeth killing the demon Hiranya.
                    </p>

                    <p>
                      Lord Brahma beautified Mother Earth with his creations.
                      Then, Hiranyaksha stole the Earth into the ocean.
                      Eventually, the Sweta Varaha – the Wild white Boar killed
                      the demon Hiranyaksha and brought back Mother Earth to its
                      original place.
                    </p>

                    <p>
                      Scientists believe that Earth had slipped from its orbit
                      at least twice till now and the Indian Scientists knew
                      about it.
                    </p>

                    <p>
                      Varahavatar symbolizes the dimension of the full animal
                      form that lived on land.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* ----------------------------Narasimha Avatar---------------------- */}
          {name === "Narasimha Avatar" ? (
            <div className="row">
              <div className="col-md-4" id=""></div>
              <div className="col-md-4" id="">
                <div className="banqImg">
                  <img src={Narshima} alt="" />
                </div>
              </div>
              <div className="col-md-4" id=""></div>
              <div className="col-md-1" id=""></div>
              <div className="col-md-10 tab-contents">
                <div className="tab-content">
                  <div role="tabpanel" className="tab-pane active">
                    <h4>Narasimha Avatar</h4>

                    <p>
                      In Narasimha avatar, Lord Vishnu appeared as a half-lion,
                      half-human creature to protect his devotee, Prahlada, from
                      his demon father, Hiranyakashipu. Narasimha avatar is
                      symbolic of divine wrath and the triumph of good over
                      evil.
                    </p>

                    <p>
                      Hiranyaksha and Hiranyakashipu were demon kings. Lord
                      Vishnu as Varaha killed Hiranyaksha. So, Hiranyakashipu
                      hated Lord Vishnu. He did penance in the name of Shiva.
                      Pleased with penance and as sought by Hiranyakashipu,
                      Shiva granted a boon whereby he could not be killed in the
                      morning, noon, or night, nor could be killed by any man or
                      animal. He could not be killed by anyone on Earth nor in
                      skies nor water, nor within or outside the house. Further,
                      he could not be killed by any weapon. Hiranyakashipu
                      thought he had fooled Shiva and he could live forever.
                      That pitiful thing Hiranyakashipu did not know that the
                      Lord could not be tricked, even with the great
                      intelligentsia he had.
                    </p>

                    <p>
                      When he was in penance, his wife and son Prahlad came
                      under the care of Narada Maharshi. Narada told them about
                      Lord Vishnu, His powers, His compassion towards devotees
                      and the power of his name when chanted. When
                      Hiranyakashipu returned, he learnt about Prahlad and his
                      devotion to Vishnu. He tried to dissuade him but failed.
                      Then, the demon, as cruel he was, tried to kill him by
                      poisoning him, dropping him from hills, drowning in the
                      seas, burning and elephant trampling. However, Prahlad
                      conquered death every time, chanting the name of Vishnu.
                      Hiranyakashipu became desperate and asked Prahlad where he
                      could find Vishnu, and that he could kill Him. Prahlad
                      said that He was omnipresent. Hiranyakashipu sarcastically
                      asked whether He could be in the Pillar next to him. When
                      Prahlad said yes, he thumped the pillar with anger. The
                      Pillar crashed; a fierce creature being half lion and half
                      human (NARA-Human, SIMHA - Animal) emerged and killed
                      Hiranyakashipu, taking him on his lap sitting on the
                      threshold and tearing him apart, using his Lion’s nails,
                      without violating Shiva’s boon to him.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* -----------------vamana avatar-------------------- */}
          {name === "Vamana Avatar" ? (
            <div className="row">
              <div className="col-md-4" id=""></div>
              <div className="col-md-4" id="">
                <div className="banqImg">
                  <img src={Vamana} alt="" />
                </div>
              </div>
              <div className="col-md-4" id=""></div>
              <div className="col-md-1" id=""></div>
              <div className="col-md-10 tab-contents">
                <div className="tab-content">
                  <div role="tabpanel" className="tab-pane active">
                    <h4>Vamana Avatar</h4>

                    <p>
                      In Vamana avatar, Lord Vishnu incarnated as a dwarf
                      Brahmin named Vamana to teach the demon king Mahabali a
                      lesson in humility. Vamana requested three steps of land
                      from Mahabali, which ultimately covered the entire
                      universe, and thus Mahabali was humbled.
                    </p>

                    <p>
                      There was a demon king named Bali, who was Prahlad’s
                      grandson. He was a highly compassionate and kind ruler.
                      Even the Gods were jealous of his fame and powers.
                    </p>

                    <p>
                      Bali had defeated Indra in warfare, and he was performing
                      Ashwamedha yaga and he could become the king of the
                      heavens. Indra wished to regain his pride and retain
                      heaven and so, he prayed to Lord Vishnu to help him.{" "}
                    </p>

                    <p>
                      King Bali was performing Ashvamedha yaga. Before the
                      sacred fire, he was granting gifts to people at large.
                      Lord Vishnu took birth this time as the son of Sage
                      Kashyapa and Aditi, known as VAMANA, a dwarf, virtuous in
                      appearance, glowing with divine powers.
                    </p>

                    <p>
                      Vamana as a Vatu approached Bali for a gift. Bali’s guru
                      Shukracharya identified the Vatu as none other than
                      Vishnu’s incarnation. Bali was pleased at his fortune that
                      the Lord himself had come to seek bhiksha from him. Vamana
                      sought space that he could measure with his foot thrice.
                      He measured the whole earth with only one foot, the whole
                      space above and other Lokas with another foot and finally
                      said that there was no space for the third foot to place.
                      Bali offered his head to Vamana, who placed his foot on it
                      and pushed him to the Patala loka and made him king of
                      that Loka. The Lord also blessed him with immortality and
                      assured that he would be his security guarding Patala
                      Loka.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* -----------------parashurama avatar-------------------- */}
          {name === "Parashurama Avatar" ? (
            <div className="row">
              <div className="col-md-4" id=""></div>
              <div className="col-md-4" id="">
                <div className="banqImg">
                  <img src={Parshurama} alt="" />
                </div>
              </div>
              <div className="col-md-4" id=""></div>
              <div className="col-md-1" id=""></div>
              <div className="col-md-10 tab-contents">
                <div className="tab-content">
                  <div role="tabpanel" className="tab-pane active">
                    <h4>Parshurma Avatar</h4>

                    <p>
                      Parashurama is the warrior-sage avatar of Lord Vishnu. He
                      was born to rid the earth of corrupt and tyrannical
                      rulers. Parashurama was known for his skill in wielding
                      the axe, and he brought about the destruction of
                      unrighteous kings during his time.
                    </p>

                    <p>
                      Parashurama is the sixth of the ten avatars of Vishnu. He
                      was born to the sage Jamadagni and his wife Renuka.
                      Jamadagni had a heavenly holy cow called Surabhi gifted by
                      Indira in his ashram. Surabhi could grant food and provide
                      anything that Jamadagni desired.
                    </p>

                    <p>
                      Once, King Kartavirya Arjuna visited his ashram with his
                      army, horses, elephants, and his retinue of attendants.
                      They were treated well by the grace of Surabhi. Knowing
                      this, the King ordered the sage to hand over the cow to
                      him. When Jamadagni did not agree, he also tried to use
                      muscular force. However, Surabhi produced an army that
                      forced the king’s army to retreat.
                    </p>

                    <p>
                      Over a period, the King tricked the Sage, killed him, and
                      kidnapped Surabhi. Parashuram (Ram with his weapon
                      -Parashu), on his return to the ashram, was raged at the
                      heinous and cowardly act of the King. He vowed that he
                      would free the Earth from Kshatriya kings. He went around
                      the world twenty-one times on an expedition to destroy the
                      Kshatriya clan. Only the kshatriyas who were performing
                      yagas or in weddings in the family, were spared.
                    </p>

                    <p>
                      Parashurama had left his Shivadhanush with Janaka, the
                      King of Videha. Sri Ram broke the bow, which led
                      Parashurama rushing to him. He challenged Sri Ram to tie
                      the string of his Narayana Dhanush, which would determine
                      his power. Sri Ram took the bow in his hands and the
                      strings automatically got tied to it. Parashurama
                      recognized Sri Ram’s divinity and blessed him.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* -----------------Rama avatar-------------------- */}
          {name === "Rama Avatar" ? (
            <div className="row">
              <div className="col-md-4" id=""></div>
              <div className="col-md-4" id="">
                <div className="banqImg">
                  <img src={Rama} alt="" />
                </div>
              </div>
              <div className="col-md-4" id=""></div>
              <div className="col-md-1" id=""></div>
              <div className="col-md-10 tab-contents">
                <div className="tab-content">
                  <div role="tabpanel" className="tab-pane active">
                    <h4>Rama Avatar</h4>

                    <p>
                      Rama is the central character of the epic Ramayana. Lord
                      Vishnu incarnated as Rama to destroy the demon king Ravana
                      and restore dharma (righteousness) in the world. Rama is
                      revered for his ideal qualities of righteousness,
                      devotion, and loyalty.
                    </p>

                    <p>
                      Sri Rama is the seventh avatar of Lord Vishnu. He was the
                      first son of King Dasharatha of Ikshvaku and Raghu clan,
                      and his first wife Kausalya. Other wives were Sumitra and
                      Kaikeyi, who gave birth to Lakshman, Bharata and
                      Shatrughna. Legend says that while Lord Vishnu took the
                      form of Rama, Seshanag, his serpent bed, took the form of
                      Lakshman, while his conch and Chakra took the form of
                      Bharat and Shatrughna, respectively.
                    </p>

                    <p>
                      He was trained by Sage Vashishta and Vishwamitra in
                      academics, holy books, administration, weaponry, warfare,
                      and spirituality. At a youthful age, Rama and his brothers
                      were moved at the world’s sorrow and misery and lacked
                      interest in royal duties and responsibilities. This led to
                      a great symposium with the learned sages of the time,
                      headed by Vashishta. The discussions and knowledge churned
                      out of it is popularly known as Yoga Vashishta. After this
                      meeting, Rama took interest in the royal affairs.
                    </p>

                    <p>
                      Obeying his father’s words, Rama renounced kingship and
                      went into jungles for 14 years. His wife Sita was
                      kidnaped. Through his search for Sita, he eliminated many
                      giants and demons, befriended Sugreeva, killed Vali, , got
                      the heroic Hanuman as his devotee and killed Ravana and
                      his coteries at Sri Lanka. Upon his return to Ayodhya, he
                      provided exemplary administration, justice, and service to
                      his people. His name today, is taken with respect and
                      reverence in India and the countries in the East.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* -----------------kalki avatar-------------------- */}
          {name === "Kalki Avatar" ? (
            <div className="row">
              <div className="col-md-4" id=""></div>
              <div className="col-md-4" id="">
                <div className="banqImg">
                  <img src={Kalki} alt="" />
                </div>
              </div>
              <div className="col-md-4" id=""></div>
              <div className="col-md-1" id=""></div>
              <div className="col-md-10 tab-contents">
                <div className="tab-content">
                  <div role="tabpanel" className="tab-pane active">
                    <h4>Kalki Avatar</h4>

                    <p>
                      Kalki avatar is believed to be the final incarnation of
                      Lord Vishnu, yet to take place in the future. It is said
                      that Kalki will appear at the end of the Kali Yuga (the
                      current age of darkness), riding a white horse to destroy
                      evil forces and restore righteousness, thus beginning a
                      new cycle.
                    </p>
                    <p>
                      In the last part of Kaliyuga (we are still in early part),
                      when indiscipline becomes the way of life, humans live
                      devoid of righteousness, the world will pass through
                      anarchy and they would live denying the existence of
                      superpower and arrogate themselves as the supreme power
                      (with the technology the way it is developing), the Super
                      power (we call Lord Vishnu) will descend on this Earth.
                    </p>
                    <p>
                      Such a dimension is known as Kalki avatar of the Lord. It
                      is prophesied that He will appear riding on a horse
                      playing a sword in his hands symbolic of granting
                      punishment to Mlechhas, the wicked, to annihilate them.
                      That could be the doomsday, the end of Kaliyug.
                    </p>
                    <p>
                      It is predicted that Uddhava, the cousin of Lord Krishna,
                      who is believed to have eternal life, would go to a place
                      called Kalapa in Himalayas and preach to King Prateepa and
                      other Kshatriyas, Dharma as taught by the Lord to him and
                      that they would resurrect Dharma on Earth, heralding
                      advent of Satya yuga.
                    </p>
                    <p>
                      The time span in the Universe is divided into four yugas,
                      namely Satya or Krita yuga, Threta yuga, Dwapara and Kali
                      yuga. It is a cycle of time span that rotates.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* -----------------------------------budha avatar--------------------- */}
          {name === "Buddha Avatar" ? (
            <div className="row">
              <div className="col-md-4" id=""></div>
              <div className="col-md-4" id="">
                <div className="banqImg">
                  <img src={Budha} alt="" />
                </div>
              </div>
              <div className="col-md-4" id=""></div>
              <div className="col-md-1" id=""></div>
              <div className="col-md-10 tab-contents">
                <div className="tab-content">
                  <div role="tabpanel" className="tab-pane active">
                    <h4>Buddha Avatar</h4>

                    <p>
                      Lord Vishnu took the form of Buddha to teach the path of
                      non-violence, compassion, and spiritual enlightenment.
                      Buddha's teachings spread far and wide, leading to the
                      formation of Buddhism. Buddha dedicated his teachings to
                      understanding the cause of suffering and finding a path to
                      liberation and peace.{" "}
                    </p>

                    <p>
                      While the rest of the avatars were undertaken to kill
                      demons and reinstate Dharma on Earth, the Buddha took
                      birth to bring peace and enlightenment to human beings.
                      This is the ninth avatar of Lord Vishnu.
                    </p>
                    <p>
                      Buddha was born as Siddhartha Gautama to King Suddhodhana
                      and queen Maya Devi. A prophesier told the King that his
                      child would either be a great emperor or a great saint who
                      would bring peace to this world. To train the child to be
                      a king, they raised him in the palace very carefully such
                      that he was not exposed to pain and sufferings daunting
                      the world.
                    </p>
                    <p>
                      But after marriage, Gautama came out of the royal fort for
                      the first time and encountered the dead body, the aged,
                      the poor, and the people with great sufferings. He
                      understood that life was otherwise than what he had
                      experienced till then. Later, he renounced worldly
                      belongings, wandered length, and breadth of the country
                      and meditated seeking answers to his various questions. At
                      last, under a peepal tree near Gaya, he attained
                      enlightenment.
                    </p>
                    <p>
                      Gautama Buddha founded Buddhism which focuses on simple
                      living, non-violence, and peace. He was against Vedic
                      sacrificial processes.
                    </p>
                    <p>
                      Note that Upanishads also preach that even Vedas, Swara,
                      Varna, Vyakarana among other knowledge tools are ‘avidyas’
                      and that highest Vidya is only the knowledge of Brahman,
                      called Brahmavidya.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* -----------------------------------budha avatar--------------------- */}
          {name === "Krishna Avatar" ? (
            <div className="row">
              <div className="col-md-4" id=""></div>
              <div className="col-md-4" id="">
                <div className="banqImg">
                  <img src={Krishna} alt="" />
                </div>
              </div>
              <div className="col-md-4" id=""></div>
              <div className="col-md-1" id=""></div>
              <div className="col-md-10 tab-contents">
                <div className="tab-content">
                  <div role="tabpanel" className="tab-pane active">
                    <h4>Krishna Avatar</h4>
                    <p>
                      Krishna, one of the most beloved and worshipped avatars of
                      Lord Vishnu, appeared in the Dvapara Yuga. He played a
                      pivotal role in the Mahabharata, guiding Arjuna as his
                      revered charioteer in the Kurukshetra war and imparting
                      spiritual wisdom through the Bhagavad Gita.
                    </p>
                    <p>
                      Lord Krishna was the eighth child of Vasudeva and Devaki.
                      Devaki was the daughter of Ugrasena and the sister of
                      Kamsa.
                    </p>
                    <p>
                      On the day of her wedding, while Kamsa was charioting her
                      and Vasudeva in a procession, a divine voice proclaimed
                      that the eighth child of Devaki would kill Kamsa. Taken
                      aback, Kamsa took out his sword to kill his sister.
                      Vasudeva consoled him that he could kill their eighth
                      child instead of killing Devaki. Not being satisfied,
                      Kamsa sent the couple to prison. He killed the first six
                      new-borns to Devaki. During her seventh pregnancy, the
                      foetus stood surrogated to Revati, Vasudeva’s other wife.
                    </p>
                    <p>
                      When Krishna was born, several miracles occurred; his
                      whole life was full of miracles. The prison chains
                      automatically got opened, the security slept in slumber,
                      and the Yamuna River gave way for Vasudeva to cross the
                      river to go to Gokul. Krishna, as a child, killed many
                      giants and monsters who were sent to kill Krishna by Kamsa
                      such as Putani, Shakata, Thrunavarta, Baka, Agha, and
                      others. In Mathura, he performed miracles including
                      breaking Shiva’s Dhanush, killing wrestlers and eventually
                      Kamsa and liberated Ugrasena and his parents from prison.
                      He also defeated Jarasandha many times, transferred the
                      kingdom to Dwaraka, a beautiful city he built at the
                      shores of Arabian sea in Gujarat.
                    </p>
                    <p>
                      He played a significant role in Draupadi marrying the five
                      Pandava brothers to keep them united in life for
                      re-establishment of Dharma, helped Pandavas to win the
                      Kurukshetra war against their cousins, gave to the world,
                      the Bhagavad Gita, a psalm of great spiritual,
                      philosophical wisdom for practically navigating one’s life
                      towards liberation from the worldly agonies.
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Avatar;
