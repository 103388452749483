import React, { useState,useLayoutEffect } from 'react';
import "../CSS/Gallery.css";
import flute from "../Images/flutelogo-removebg-preview.png"
import Tabs from "./Tabs";
import Items from "./Items";
import GalleryData from '../component/GalleryData';
// import Bounce from 'react-reveal/Bounce';
import Footer from '../component/Footer';
import { MetaTags } from 'react-meta-tags';
const Gallery = () => {


    useLayoutEffect(() => {
        window.scrollTo(0, 0)
      });

    const [data, setData] = useState(GalleryData)

    // Store Category in CategoryData 
    const categoryData = GalleryData.map((value) => {
        return value.Category
    });
    ////////////

    const tabsData = ["all", ...new Set(categoryData)];


    // for all categoryData set 
    const filterCategory = (Category) => {
        if (Category == "all") {
            setData(GalleryData)
            return;
        }
        const filteredData = GalleryData.filter((value) => {
            return value.Category == Category;
        })

        setData(filteredData);

    }


    return (
        <>
 {/* ========= SEO ========== */}

 <MetaTags>
        <title>Top Hall In Sion | Gokul Events</title>
        <meta
          name="description"
          content="Elevate your events to new heights with the Top Hall in Sion. Explore our premier venue for unforgettable gatherings and celebrations."
        />

        <meta property="og:title" content="Top Hall in Sion | Gokul Events" />
        <meta
          property="og:description"
          content="Elevate your events to new heights with the Top Hall in Sion. Explore our premier venue for unforgettable gatherings and celebrations."
        />
        <meta
          name="keywords"
          content="Hall In Sion,
          Party Hall In Sion,
          Banquet in Sion,
          Wedding Banquet In Sion,
          Best Banquet In Sion,
          Party Banquet In Sion,
          Gokul Hall,
          auditorium In Sion,
          AC Hall In Sion,
          AC Wedding Halls In Sion,
          Roof Top Hall In Sion"
        />
        <link rel="canonical" href="https://gokulevents.in/top-hall-sion" />
        <meta name="google-site-verification" content="VBB-V05nLFwZS9pwXxrINkZ79RHxEazT6XA_NDNapFA" />
      </MetaTags>

      {/* ======== SEO end ======= */}
            {/* ///////////////////////////////// section 1 ////////////////////////////////// */}
            <section class="aboutInfoSection pt-5">
        <div class="container">
          <div class="aboutHomeHead">
          {/* <div className="bannerLogo">
        <img src={flute} alt=""/>
       </div> */}
            <h1 class="d-none">
            Top Hall in Sion | Gokul Events
                </h1>
                {/* <Bounce top cascade> */}
                <h2>Select Event</h2>
                {/* </Bounce> */}
          
          </div>
        </div>
      </section>
          

            {/* ///////////////////////////////// section 2 ////////////////////////////////// */}

            <div className='Gallery-Section-2'>
                <div className='Gallery-Card'>

                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Tabs filterCategory={filterCategory} tabsData={tabsData} />
                                <Items data={data} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}

export default Gallery