import React, { useState} from 'react';
import logo from "../Images/logo__2_-removebg-preview.png";
import "../CSS/Navbar.css";
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import flute from "../Images/flutelogo-removebg-preview.png"
const Navbar1 = () => {
  // const [ scroll,setScroll] = useState(false);
  const [expanded, setExpanded] = useState(false);

  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     setScroll(window.scrollY > 50);
  //   });
  // }, []);

  const closeNavbar = () => {
    setExpanded(false);
  };

  return (
    <div>
      <Navbar
        collapseOnSelect
        expand="lg"
       
        // variant="dark"
        expanded={expanded}
      >
        <Container>
          <Navbar.Brand className="logo">
            <Link to="/" onClick={closeNavbar}>
              <img className="logo-two" src={logo} alt="logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() => setExpanded(!expanded)}
          />
          <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-start">
            <Nav className="me-auto">
              <Link to="/" className="nav-link" onClick={closeNavbar}>
                Home
              </Link>
             
            
              <Link to="/banquet-in-sion" className="nav-link" onClick={closeNavbar}>
                Services
              </Link>
              <Link to="/Seva" className="nav-link" onClick={closeNavbar}>
                Seva
              </Link>
              <Link to="/carvings" className="nav-link" onClick={closeNavbar}>
                Carvings
              </Link>
              <Link to="/party-hall-in-sion" className="nav-link" onClick={closeNavbar}>
                About
              </Link>
              <Link to="/top-hall-sion" className="nav-link" onClick={closeNavbar}>
                Gallery
              </Link>
              <Link to="/Dashavtar" className="nav-link" onClick={closeNavbar}>
                Dashavtar
              </Link>
              <Link to="/party-banquet-in-sion" className="nav-link" onClick={closeNavbar}>
                Contact
              </Link>
            
            </Nav>
            <div className="bannerLogo d-lg-block d-none">
              <Link to="/">
            <img src={flute} alt="" /></Link>
          </div>
            {/* <button className="viewBtn d-lg-flex d-none">
              <Link to="/Contact" target="_blank">
                Connect
              </Link>
            </button> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Navbar1;