import { React, useLayoutEffect } from "react";
import "../CSS/About.css";
import Footer  from '../component/Footer';
import {MetaTags} from "react-meta-tags";
import flute from "../Images/flutelogo-removebg-preview.png"
// import flute from "../Images/flute.png";
// import krish from "../Images/banq.jpg";
// import {Link} from 'react-router-dom';
const About = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  return (
    <div>
{/* ========= SEO ========== */}

<MetaTags>
        <title>
        Party Hall In Sion | Gokul Events
        </title>
        <meta name="title" title="Party Hall In Sion | Gokul Events" />
        <meta
          name="description"
          content="Discover the perfect party hall in Sion for Gokul Events. Celebrate in grandeur and create cherished memories. Contact Us Now!"
        />
       
        <meta
          property="og:title"
          content="Party Hall In Sion | Gokul Events"
        />
        <meta
          property="og:description"
          content="Discover the perfect party hall in Sion for Gokul Events. Celebrate in grandeur and create cherished memories. Contact Us Now!"
        />
        <meta
          name="keywords"
          content="Hall In Sion,
          Party Hall In Sion,
          Banquet in Sion,
          Wedding Banquet In Sion,
          Best Banquet In Sion,
          Party Banquet In Sion,
          Gokul Hall,
          auditorium In Sion,
          AC Hall In Sion,
          AC Wedding Halls In Sion,
          Roof Top Hall In Sion"
        />
        <link rel="canonical" href="https://gokulevents.in/party-hall-in-sion" />
        <meta name="google-site-verification" content="VBB-V05nLFwZS9pwXxrINkZ79RHxEazT6XA_NDNapFA" />
      </MetaTags>

      {/* ======== SEO end ======= */}

        <section class="aboutInfoSection">
        <div class="container">
            <div class="aboutHomeHead">
              <h1 className="d-none">
              Party Hall In Sion | Gokul Events
              </h1>
              {/* <div className="bannerLogo">
        <img src={flute} alt=""/>
       </div> */}
                <h2 class="">
                The Art of Event Planning
               
                </h2>
                
            </div>
        </div>
    </section>

{/* ---------------------------plan event-------------------------- */}
 

    
        <section id="whatWeDid" className=" contentRowPad">
  <div className="container">
    <div className="row">
      <div className="col-md-6 tab_menu">
      <div className="row m0">  
        </div>
      </div>
      <div className="col-md-6 tab-contents">
        <div className="tab-content">
          <div role="tabpanel" className="plan-discription" id="wwd2018">
            {/* <h4>A place of peace and sprituality</h4> */}
            <h3>About Gokul Events</h3>
            
            <p>
            Party Hall in Sion stands as a testament to elegance and versatility, a space where celebrations and gatherings transform into unforgettable experiences. With its spacious layout and customizable decor, it serves as a blank canvas, ready to be tailored to any event's theme or tone. Whether it's a glamorous wedding reception, a corporate seminar, or a joyous family reunion, the banquet hall's ambiance lends itself perfectly to create an atmosphere of grandeur and warmth. Equipped with modern amenities and impeccable design, it seamlessly blends practicality with aesthetics, making it an ideal choice for hosting occasions that deserve nothing less than excellence.
 </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>



<section className="aboutSection">
        <div className="container">
          <div className="row">
            <div className="aboutDesc">
              <h2 className="plan-heading">
              Explore Our Modern Facilities
              </h2>
              <p className="plan-sub-heading">
               

              Our facilities are equipped with cutting-edge technology and modern design, ensuring a premium experience for all our visitors.

              </p>
              <p className="plan-sub-heading">
              Our team understands that every occasion holds its own significance and story, with a promise. With a meticulous blend of creativity, organization and dedication, we lay the foundation for your event's success.
              </p>
              {/* <p>
                <Link className="btn viewBtn1" to="About">
                  Read More
                </Link>
              </p> */}
            </div>
          </div>

          <div className="row serviceDetails text-center">
            {/* <div className="col-lg-8"> */}
            <div className="col-md-6 col-6 d-flex justify-content-center">
              <ul className="m-0 p-0">
                <li className="">
                  <i className="fa fa-parking">
                    {" "}
                    <span>60 car (Valet Parking Available)</span>
                  </i>
                </li>
                <li>
                  {" "}
                  <i className="fas fa-home">
                    <span>Customized Decoration </span>
                  </i>
                </li>
                <li>
                  {" "}
                  <i className="fas fa-location-arrow">
                    <span> Centrally Located </span>
                  </i>
                </li>
                <li>
                  <i className="fa fa-fan">
                    <span>CENTRALLY AIR CONDITIONED HALLS</span>
                  </i>
                </li>
                <li>
                  {" "}
                  <i className="fas fa-users">
                    <span>Available for all kinds of services</span>
                  </i>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-6 ">
              <ul className="m-0 p-0">
                <li className="">
                  <i className="fas fa-utensils">
                    {" "}
                    <span>Providing Pure Vegetarian Meal </span>
                  </i>
                </li>
                <li>
                  {" "}
                  <i className="fas fa-restroom">
                    <span>Availability Of Changing Rooms </span>
                  </i>
                </li>
                <li>
                  {" "}
                  <i className="fas fa-chair">
                    <span>Seperate Auditorium</span>
                  </i>
                </li>
                <li>
                  <i className="fa fa-list">
                    <span>CUSTOMIZED PACKAGE AS PER NEEDS</span>
                  </i>
                </li>
                <li>
                  {" "}
                  <i className="fas fa-camera-retro">
                    <span> Equipped With CCTV Cameras</span>{" "}
                  </i>
                </li>
              </ul>
            </div>

      
          </div>
        </div>
      </section>
{/* ---------------------------------you get---------------------------- */}

<section id="hww" className=" contentRowPad">
  <div className="container">
  <div className="aboutDesc">
              <h2 className="plan-heading">You will get</h2>
              </div>
    <div className="row">
      <div className="col-lg-4  col-12 mt-5">
        <div className="audit">
        <h5>
          <span>01.</span>Temple
        </h5>
        <p>
        A serene haven for spiritual contemplation, where the echoes of devotion and tranquility merge. Immerse yourself in the divine aura and find solace within the sacred walls.

        </p>
        </div>
      </div>
      <div className="col-lg-4  col-12  mt-5">
      <div className="audit">
        <h5>
          <span>02.</span> Banquet
        </h5>
        <p>
        A canvas of elegance and celebration, awaiting your unique touch. Customize the space to craft an event that mirrors your vision, whether it's a dreamy wedding or a corporate gala.

        </p>
        </div>
      </div>
      <div className="col-lg-4 col-12  mt-5">
      <div className="audit">
        <h5>
          <span>03.</span> Auditorium
        </h5>
        <p>
        The stage for knowledge and inspiration, where ideas take center spotlight. Experience seamless acoustics and comfortable seating as you share, learn, and engage in this space designed to elevate presentations, seminars and performances.

        </p>
        </div>
      </div>
    </div>
  </div>
</section>

<Footer/>
    </div>
  )
}

export default About