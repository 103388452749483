// import logo from './logo.svg';
import './App.css';
import NavBar1 from './component/Navbar1';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from './Pages/Home';
import Service from './Pages/Service';
import Contact from "./Pages/Contact";
import Murals from "./Pages/Murals";
import About from "./Pages/About";
import Seva from "./Pages/Seva";
import Gallery from "./Pages/Gallery";
import Banquets from './Pages/Dashavtar';
import Carvings from "./Pages/Carvings";
import Avatar from './Pages/Avatar';

import Errorpage from './Pages/Errorpage';
import Mahadwara from './Pages/Mahadwara';
function App() {
  return (
    <div className="App">
     <BrowserRouter>
        <NavBar1 />
        {/* <Call/> */}
        {/* <WhatsApp/> */}
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/party-hall-in-sion' element={<About />} />
          <Route path='/Seva' element={<Seva />} />
          <Route path='/banquet-in-sion' element={<Service/>} />
          <Route path='/Dashavtar' element={<Banquets/>} />
         
          <Route path='/Mahadwara' element={<Mahadwara/>} />
          <Route path='/top-hall-sion' element={<Gallery/>} />
          <Route path='/party-banquet-in-sion' element={<Contact/>} />
          <Route path='/Avatar/:name' element={<Avatar/>} />
          <Route path='/*' element={<Errorpage/>} />
          <Route path='/Carvings' element={<Carvings/>}/>
          <Route path='/Murals' element={<Murals/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
