import React from "react";
import { Link } from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import Footer from "../component/Footer";
import gopura from "../Images/Ceiling/Gopura.JPG";
import vishnu from "../Images/Ceiling/Mahavishnu in Sheshashayan.JPG";
import jaya from "../Images/Ceiling/Jaya n Vijaya.jpg";
import lobby from "../Images/Ceiling/Main Lobby.JPG";
import maha from "../Images/Mahadwara.JPG";
const Carvings = () => {
  return (
    <div>
      {/* ======== SEO end ======= */}
      <section class="aboutInfoSection pt-5">
        <div class="container">
          <div class="aboutHomeHead">
            <h1 class="d-none"></h1>
            {/* <div className="bannerLogo">
        <img src={flute} alt=""/>
       </div> */}
            <h2 id="carv">  Carvings</h2>
          </div>
        </div>
      </section>

      <section id="wwd2018" className="avatarSection">
        <div className="container">
          <div className="row">
            {/* <div className="col-md-12 tab-contents">
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active">
                  <h4>Ceiling Carvings</h4>
                </div>
              </div>
            </div> */}

            {/* ---------------------------------Gpura /Tower------------------ */}
            <div className="row pt-5" id="Gopura">
            <div className="col-lg-6 col-12 tab-contents order-lg-1 order-2" id="saraswati-auditorium">
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className="" style={{fontSize:"20",fontFamily:"sans-serif"}} > Gopura / Tower</h3>

                  <p>
                    The
                 
                      {" "}
                      Gopura / Tower{" "}
                
                    is built on five bases that are vertically oriented
                    consistent with Dravidian Architecture. The carefully
                    crafted stupas are constructed with special material to give
                    it a magnificent look.
                  </p>

                  <p>
                    The Gopura is built-in accordance with the agama that each
                    individual temple follows. Additionally, they are
                    constructed based on the Vastu and the Shilpa Shastra. In
                    building the temple's gopuram, there is a rule that, as the
                    main temple's center is approached, the size of the doors
                    must be reduced. That explains why a temple has outer ones
                    that are very massive and tall and inner ones that are
                    smaller. This is a symbolic way of explaining the process of
                    sanctification in a spiritual journey.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 order-lg-2 order-1">
              <div className="banqImg">
                <img src={gopura} alt="banquet halls" />
              </div>
            </div>
            </div>
            {/* ----------------------sage valmiki------------------- */}
            <div className="row pt-5 " id="shayan">
            <div className="col-lg-6 col-12" id="janardhan-ac-hall">
              <div className="banqImg">
                <img src={vishnu} alt="banquet halls"  />
              </div>
            </div>
            <div className="col-lg-6 col-12 tab-contents">
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                <h3 className="" style={{fontSize:"20",fontFamily:"sans-serif"}}> Mahavishnu in Sheshashayan</h3>

                  <p>
                    
                      Mahavishnu in Sheshashayan{" "}
                   
                    posture, installed above the Mahadwaras is an exquisitely
                    carved wooden carving of Lord Vishnu resting in the serpent
                    bed of Sheshashayan in the celestial ocean known as Ksheera
                    Sagara.
                  </p>
                </div>
              </div>
            </div>
            </div>
            {/* ---------------------------------jaya------------------ */}
            <div className="row pt-5" id="jaya">
            <div className="col-lg-6 col-12 tab-contents order-lg-1 order-2" id="">
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                <h3 className="" style={{fontSize:"20",fontFamily:"sans-serif"}}>
                Jaya & Vijaya</h3>

                  <p>
                    
                   
                      Jaya & Vijaya,
               
                    are the two Dwarapalakas / gatekeepers of the abode of Lord
                    Vishnu. Installed on both sides of the main doors at the
                    entrance are two beautifully sculpted statues based on
                    Hoysala style and created by sculptors of Karkala. Jaya and
                    Vijaya who were forced to undergo multiple births as
                    mortals, due to a curse, were incarnated as Hiranyakashipu &
                    Hiranyaksha in Satya Yuga, Ravana, and Kumbhakarna in the
                    Treta Yuga, and finally as Shishupala and Dantavakra in the
                    Dvapara Yuga.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 order-lg-2 order-1">
              <div className="banqImg">
                <img src={jaya} alt="banquet halls" />
              </div>
            </div>
            </div>
            {/* ------------------mahadwara--------------------- */}
            <div className="row pt-5" id="Maha">
            <div className="col-lg-5 col-12 pt-md-5">
              <div className="banqImg ">
                <img src={maha} alt="banquet halls" />
              </div>
              </div>
      <div className="col-lg-7 col-12 col-12 tab-contents">
        <div className="tab-content">
       
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                <h3 className="" style={{fontSize:"20",fontFamily:"sans-serif"}}>
                Mahadwara</h3>
            
            <p>
            The awe-inspiring wooden doors,  the Mahadwaras , installed at the entrance have <HashLink to="/Mahadwara#eight"> twenty-eight frames </HashLink>, beautifully decorated by intricate wooden carvings depicting Lord Krishna’s childhood.

Many remarkable incidents like the banishing of the serpent Kalinga, Krishna’s love for his friends and playmates and his playful pastimes with the Gopis, who considered him epitome of charm and divine love, are depicted here in these beautiful frames of the Mahadwaras.

Also depicted is Lord Krishna’s and mother Yashoda’s special and affectionate bond. Yashoda was Krishna's foster mother and she loved him deeply. She played an essential role in Krishna's childhood and upbringing. Yashoda took care of Krishna with great devotion, showering him with unconditional love and affection. Krishna, in turn, adored Yashoda and considered her as his own mother. Their relationship was filled with love, playfulness, and trust. Krishna would often engage in mischievous activities, and Yashoda would lovingly scold him while cherishing every moment spent with him. Overall, their relationship exemplifies the beautiful bond between a mother and child. 

Every frame has a wonderful story to tell, and you may not be faulted if you get stranded here. 
 </p>
          </div>
          </div>
          </div>
       
    
    </div>
            {/* ----------------------sage valmiki------------------- */}
            <div className="row pt-5" id="lobby">
            <div className="col-lg-6 col-12" id="Maha">
              <div className="banqImg">
                <img src={lobby} alt="banquet halls" />
              </div>
            </div>
            <div className="col-lg-6 col-12 tab-contents">
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                <h3 className="" style={{fontSize:"20",fontFamily:"sans-serif"}}>  Main Lobby</h3>

                  <p>
                    As you enter the{" "}
                   
                      Main Lobby{" "}
                  
                    of the temple, you witness the grandeur of wooden carvings
                    and    <Link
                      to="/Murals"
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        // borderBottom: "2px solid #000",
                      }}
                    >murals</Link> in the Main Hall depicting inspiring scenes from
                    the Puranas carved to microscopic accuracy. Each wooden
                    <Link
                      to="/Murals"
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        // borderBottom: "2px solid #000",
                      }}
                    > mural</Link> intricately designed and meticulously crafted tells
                    you a story and you can immerse yourself in it for hours.
                    From Vasudeva’s journey carrying baby Krishna in a basket
                    under Shesha’s protection, to His birth in the jails of
                    Mathura. From the depiction of all the mischievous and
                    playful deeds of Lord Krishna like stealing of butter as
                    Yadu Nandana, to his dance atop the hood of the mighty
                    Kalinga and to the lifting of the Govardhan mountain to
                    protect the people of Vrindavan from the wrath of Indra, the
                    murals carry the life story of the dark-skinned flautist
                    adoring a peacock feather in his hair.
                  </p>
                </div>
              </div>
            </div>
            </div>
            {/* -------------------------------murals-------------- */}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Carvings;
