
////////////// Door Images //////////////////


// ------------------conferences--------------------
import religious1 from "../Images/services/religious.jpeg";
import religious2 from "../Images/services/religious1.jpeg";
import religious3 from "../Images/services/religious3.jpeg";
import religious4 from "../Images/services/religious4.jpeg";
import religious5 from "../Images/services/religious_shani.jpeg";
// import religious6 from "../Images/services/havan2.jpeg";

// ------------classes--------------------------------
import class1 from "../Images/services/classes.jpeg";
// import class2 from "../Images/services/classes1.jpeg";
// -----------------performnces-----------------
import perform1 from "../Images/services/performance-dance.jpeg";
import perform2 from "../Images/services/dance.jpeg";
import perform3 from "../Images/services/Dramatic.jpeg";
import perform4 from "../Images/services/karaoke.jpg";
import music from "../Images/Temple/music-concert.jpeg";
// -------------------corporate events--------------------


import corp1 from '../Images/gallery/IMG_7192.jpg';
import corp2 from '../Images/gallery/IMG_7469.jpg';
import corp3 from '../Images/Product_Launch.webp';
import corp4 from "../Images/live.webp";

////////////// BedRoom Images //////////////////

import wedding from "../Images/Temple/stagedecor.JPG";
import wedding2 from "../Images/Temple/ganpati pooja.jpg";
import wedding3 from "../Images/Temple/brideenter.JPG";
import wedding4 from "../Images/Temple/couple.JPG";
import wedding5 from "../Images/wedding.jpg";
import bday from "../Images/baby.jpg";
import wedding6 from "../Images/Temple/selfiepoint.JPG";
// import wedding7 from "../Images/Temple/pataldine.JPG";
////////////// Temple Images //////////////////
import dandiya from "../Images/dandiya2.jpg";

// import Temple01 from "../Images/conference-hall.jpg";
// import Temple02 from "../Images/conference-hall.jpg";
// import Temple03 from "../Images/conference-hall.jpg";
// import Temple04 from "../Images/conference-hall.jpg";
// import Temple05 from "../Images/conference-hall.jpg";


const GalleryData = [
  {
    id: 1,
    Category:"Corporate Functions",
    CategoryName:"Workshop",
    CImage:corp1,
  },
  {
    id: 1,
    Category:"Corporate Functions",
    CategoryName:"Conferences",
    CImage:corp2,
  },
  {
    id: 1,
    Category:"Corporate Functions",
    CategoryName:"Seminars",
    CImage:corp3,
  },
  {
    id: 1,
    Category:"Corporate Functions",
    CategoryName:"Corporate Meetings",
    CImage:corp4,
  },
  // {
  //   id: 1,
  //   Category:"Corporate Functions",
  //   CategoryName:"AGM's",
  //   CImage:sitti,
  // },
  // {
  //   id: 1,
  //   Category:"Corporate Functions",
  //   CategoryName:"Product Launches",
  //   CImage:sitting,
  // },
  // {
  //   id: 1,
  //   Category:"Corporate Functions",
  //   CategoryName:"Press Conference",
  //   CImage:sitting,
  // },
  {
    id: 2,
    Category:"Family Functions",
    CategoryName:"Birthday",
    CImage:bday,
  },
  {
    id: 2,
    Category:"Family Functions",
    CategoryName:"Haldi",
    CImage:wedding6,
  },
  {
    id: 2,
    Category:"Family Functions",
    CategoryName:"Wedding",
    CImage:wedding,
  },
  {
    id: 2,
    Category:"Family Functions",
    CategoryName:"Engagement",
    CImage:wedding2,
  },
  {
    id: 2,
    Category:"Family Functions",
    CategoryName:"Reception",
    CImage:wedding3,
  },
  {
    id: 2,
    Category:"Family Functions",
    CategoryName:"Baby Shower",
    CImage:wedding4,
  },
  {
    id: 2,
    Category:"Family Functions",
    CategoryName:"Thread Ceremony",
    CImage:wedding5,
  },
 
  {
    id: 3,
    Category:"Performances",
    CategoryName:"Dance",
    CImage:perform1,
  },
  {
    id: 3,
    Category:"Performances",
    CategoryName:"Music Concerts",
    CImage:music,
  },
  {
    id: 3,
    Category:"Performances",
    CategoryName:"Private Screening",
    CImage:perform3,
  },
  // {
  //   id: 3,
  //   Category:"Performances",
  //   CategoryName:"Live streaming",
  //   CImage:perform3,
  // },
  {
    id: 3,
    Category:"Performances",
    CategoryName:"Karaoke",
    CImage:perform4,
  },
  // {
  //   id: 3,
  //   Category:"Performances",
  //   CategoryName:"Theater",
  //   CImage:perform2,
  // },
 
  {
    id: 4,
    Category:"Social Events",
    CategoryName:"Dandiya",
    CImage:dandiya,
  },
  // {
  //   id: 4,
  //   Category:"Social Events",
  //   CategoryName:"Evening Parties",
  //   CImage:couple,
  // },
  // {
  //   id: 4,
  //   Category:"Social Events",
  //   CategoryName:"Festival Celebration",
  //   CImage:couple,
  // },
  // {
  //   id: 4,
  //   Category:"Social Events",
  //   CategoryName:"Kitty Parties",
  //   CImage:couple,
  // },
  // {
  //   id: 4,
  //   Category:"Social Events",
  //   CategoryName:"School Reunion",
  //   CImage:couple,
  // },
  // {
  //   id: 4,
  //   Category:"Social Events",
  //   CategoryName:"Exhibition",
  //   CImage:couple,
  // },
  // {
  //   id: 5,
  //   Category:"Classes",
  //   CategoryName:"Yoga",
  //   CImage:phere,

  // },
  // {
  //   id: 5,
  //   Category:"Classes",
  //   CategoryName:"Meditation",
  //   CImage:phere,

  // },
  {
    id: 5,
    Category:"Classes",
    CategoryName:"Dance/Music",
    CImage:class1,

  },
  {
    id: 5,
    Category:"Classes",
    CategoryName:"Language",
    CImage:perform2,

  }, 
  // {
  //   id: 5,
  //   Category:"Classes",
  //   CategoryName:"Dramatics",
  //   CImage:perform3,

  // },

  {
    id:6,
    Category:"Religious",
    CategoryName:"Satyanarayan Puja",
    CImage:religious1,
  },
  {
    id:6,
    Category:"Religious",
    CategoryName:"Shani Puja",
    CImage:religious2,
  },
  {
    id:6,
    Category:"Religious",
    CategoryName:"Havans",
    CImage:religious3,
  },
  {
    id:6,
    Category:"Religious",
    CategoryName:"Durga Namaskara",
    CImage:religious4,
  },
  {
    id:6,
    Category:"Religious",
    CategoryName:"Griha Shanti",
    CImage:religious5,
  },
  


];

export default GalleryData;

