import React, { useState, useEffect,useLayoutEffect } from "react";
import flute from "../Images/flutelogo-removebg-preview.png";
import "../CSS/Banquets.css";
import Footer from "../component/Footer";
import TextToSpeech from "../component/TextToSpeech"; 

import { Link } from "react-router-dom";

import Avatardata from "../component/Avatardata";
// import {MetaTags} from "react-meta-tags";
const Dashavtar = ()  => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  const id=2;

  
  return (
    <div>
      {/* ========= SEO ========== */}

      {/* <MetaTags>
        <title>
        Wedding Hall in Sion | Gokul Events
        </title>
        <meta name="title" title="Wedding Hall in Sion | Gokul Events" />
        <meta
          name="description"
          content="Make your wedding dreams come true at our charming wedding hall in Sion.  Events- where love and elegance unite."
        />
       
        <meta
          property="og:title"
          content="Wedding Hall in Sion | Gokul Events"
        />
        <meta
          property="og:description"
          content="Make your wedding dreams come true at our charming wedding hall in Sion. Gokul Events- where love and elegance unite."
        />
        <meta
          name="keywords"
          content="Hall In Sion,
          Party Hall In Sion,
          Banquet in Sion,
          Wedding Banquet In Sion,
          Best Banquet In Sion,
          Party Banquet In Sion,
          Gokul Hall,
          auditorium In Sion,
          AC Hall In Sion,
          AC Wedding Halls In Sion,
          Roof Top Hall In Sion"
        />
        <link rel="canonical" href="/wedding-hall-in-sion" />
      </MetaTags> */}

      {/* ======== SEO end ======= */}
      <section class="aboutInfoSection pt-5">
        <div class="container">
          <div class="aboutHomeHead">
            <h1 class="d-none">
           
                </h1>
                {/* <div className="bannerLogo">
        <img src={flute} alt=""/>
       </div> */}
            <h2> Lord Vishnu Avatars </h2>
          </div>
        </div>
      </section>
     {/* --------------------------------banquets------------------------- */}
     <section className="kc-elm kc-css-160797 kc_row">
        <div className="container">
          <div className="row">
            <div className="aboutDesc">
              <h2 className="section-heading">Dashavtars of Lord Vishnu</h2>
              <p className="section-sub-heading" style={{textAlign:"center"}}>
              Lord Sri Krishna, the eighth avatar of Lord Vishnu, is revered as the Supreme Being and the embodiment of divine love and wisdom. The surrounding walls of the temple’s main lobby depict the Dashaavatara of Lord Vishnu, all made from gold and silver plating.
              </p>
              <p className="section-sub-heading" style={{textAlign:"center"}}>
              Dashavatara, also known as the ten avatars or incarnations of Lord Vishnu, are ten divine manifestations that are believed to have been taken by Lord Vishnu over different ages to restore balance and protect the universe from various threats. Each avatar serves a specific purpose and holds symbolic significance.
              </p>
            </div>
          </div>

          <div className="row">
           
            {Avatardata.map((elem) => {
              const { id, Avatarname , AImage,  Avtardsc} = elem;
              return (
                <>
            <div className="col-lg-4 mt-lg-4">
              <div class="card">
                <div class="img-box">
                  <img src={AImage} />
                </div>
                <div class="content">
                  <div className="city-dtl">
                    <h2 className="city-dtl-heading">{Avatarname}</h2>

                    <p>{Avtardsc}
                   
                
                    </p>
                    {/* <TextToSpeech text={Avtardsc} /> */}
                    <Link to={`/Avatar/${Avatarname}`}>
                      <button className="btn viewBtn">
                        {" "}
                      Read More
                      </button></Link>
                  
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-6"> */}

              {/* </div> */}
            </div>
            </>
              );
            })}
         
          </div>
        </div>
      </section>
      <Footer/>
    </div>
  );
};

export default Dashavtar;
