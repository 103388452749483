import React from "react";



import "../CSS/Service.css";
import Footer from "../component/Footer";
// ----------------------Murals--------------------
import leela from "../Images/Temple/makhan chor.jpg";
import jail from "../Images/Temple/jail.jpg";
import vasudeva from "../Images/Temple/vasudeva.jpg";

import kalinga from "../Images/Temple/kalinga.jpg";
import govardhan from "../Images/Temple/govardhan.jpg";
import wed from "../Images/Temple/krishnamarriage.jpg";
import tulabhra from "../Images/Temple/tulabhra.jpg";
import draupadi from "../Images/Temple/Draupadi.jpg";
import sudhama from "../Images/Temple/sudhama.jpg";
import jamvanti from "../Images/Temple/krishnamarriage.jpg";
import geeta from "../Images/Temple/geeta.jpg";
import vishwa from "../Images/Temple/vishwaroopa.jpg";
import gajendra from "../Images/Temple/IMG_4027.JPG";
import valmiki from "../Images/Ceiling/Sage Valmiki.jpg";
import Vedavyasa from "../Images/Ceiling/Vedavyasa.jpg";
import udupi from "../Images/Ceiling/Udupi Kadagolu Krishna.jpg";

const Murals = () => {
  return (
    <div>
      <section className="aboutInfoSection pt-5">
        <div className="container">
          <div className="aboutHomeHead">
            <div className="bannerLogo">
              <img src="" alt="" />
            </div>
            <h2>16 Murals </h2>
          </div>
        </div>
      </section>

      {/* -----------------16 Murals-------------------- */}

      <section id="whatWeDid" className=" contentRowPad">
        <div className="container">
          <div className="row pt-5">
            <div className="col-md-6 " id="gokulhall">
              <div className="banqImg">
                <img src={Vedavyasa} alt="banquet halls" />
              </div>
            </div>
            <div className="col-md-6  tab-contents">
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active">
                  <h3>Lord Vedavyasa</h3>
                  <p className="d-none">gokulhall</p>
                  <p>
                    Lord Vedavyasa, the author of the Mahabharata, wanted to
                    document the events of the epic for future generations, but
                    he needed a skilled and quick-witted scribe who could keep
                    up with his dictation.
                  </p>
                  <p>
                    Vedavyasa sought the help of Lord Ganesha, the
                    elephant-headed deity and the remover of obstacles.
                    Ganapathi, being the embodiment of wisdom and knowledge,
                    agreed to assist Vedavyasa on one condition. He asked
                    Vedavyasa to recite the entire Mahabharata continuously
                    without any pauses or breaks.
                  </p>
                </div>
              </div>
            </div>
           
            {/* ---------------------------------udupi------------------ */}
            <div className="row pt-5">
            <div className="col-md-6 tab-contents order-lg-1 order-2" id="saraswati-auditorium">
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className="">Udupi Kadagolu</h3>

                  <p>
                    Udupi Kadagolu Krishna is Lord Krishna, as worshipped in the
                    town of Udupi, located in the southern state of Karnataka,
                    India. The deity of Udupi Shri Krishna is housed in the
                    famous Udupi Sri Krishna Matha. The Udupi Sri Krishna Matha
                    is a popular pilgrimage site and a renowned center of Dvaita
                    philosophy, which was propagated by the great
                    philosopher-saint Shri Madhwacharya. Lord Krishna is
                    depicted in a unique standing posture with a charming smile
                    on his face, holding a churner (murali) in one hand and a
                    rope in the other.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 order-lg-2 order-1">
              <div className="banqImg">
                <img src={udupi} alt="banquet halls" />
              </div>
            </div>
            </div>
          
            {/* ----------------------sage valmiki------------------- */}
            <div className="row pt-5" id="valmiki">
              <div className="col-md-6" id="">
                <div className="banqImg">
                  <img src={valmiki} alt="banquet halls" />
                </div>
              </div>
              <div className="col-md-6 tab-contents order-lg-1 order-2">
                <div className="tab-content">
                  <div role="tabpanel" className="tab-pane active" id="wwd2018">
                    <h3 className="">Sage Valmiki</h3>

                    <p>
                      Sage Valmiki is the ancient poet who is credited with
                      writing the epic Hindu scripture, the Ramayana. The
                      Ramayana is a tale of adventure, love, and heroism, and it
                      is considered one of the two major Sanskrit epics of
                      ancient Indian literature, the other being the
                      Mahabharata. The Ramayana narrates the story of Lord Rama,
                      his wife Sita, and his loyal devotee Hanuman, as they
                      embark on a journey to rescue Sita from the demon king
                      Ravana. It is believed that Sage Valmiki composed the
                      Ramayana in Sanskrit around 500 BCE. His masterpiece
                      continues to be revered and cherished by people around the
                      world.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* -------------------------------------Kishna birth----------------- */}
            <div className="row pt-5" id="birth">
              <div
                className="col-md-6 order-lg-1 order-2 tab-contents"
                id="kiran-bala-shagrithaya-hall"
              >
                <div className="tab-content">
                  <div role="tabpanel" className="tab-pane active" id="wwd2018">
                    <h3 className="">Lord Krishna's birth</h3>

                    <p>
                      The story of Lord Krishna's birth in jail is a significant
                      event in Hindu mythology. Lord Krishna was born in Mathura
                      to Devaki and Vasudeva. However, at the time of Krishna's
                      birth, Devaki and Vasudeva were imprisoned by Devaki's
                      wicked brother, King Kansa. It was prophesied that the
                      eighth son of Devaki would be Kansa's downfall, so he
                      imprisoned the couple as a precaution. Despite being in
                      jail, Lord Krishna's divine powers ensured that he would
                      fulfill his purpose and bring about the downfall of Kansa
                      and his tyranny.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="banqImg">
                  <img src={jail} alt="banquet halls" />
                </div>
              </div>
            </div>
            {/* ----------------------------vasudeva------------------- */}
            <div className="row pt-5" id="vasudev">
              <div className="col-md-6">
                <div className="banqImg">
                  <img src={vasudeva} alt="terrace" />
                </div>
              </div>
              <div className="col-md-6 tab-contents order-lg-1 order-2" >
                <div className="tab-content">
                  <div role="tabpanel" className="tab-pane active" >
                    <h3 className="">Lord Vasudeva carrying Krishna</h3>

                    <p>
                      Lord Vasudeva carrying Krishna across the river Yamuna is
                      a remarkable incident in Hindu mythology. Understanding
                      the divine nature of his son, Vasudeva, following divine
                      guidance, undertook a perilous journey to ensure Krishna's
                      safety. Despite the heavy rain and the flooded Yamuna
                      River, Vasudeva carried baby Krishna in a wicker basket on
                      his head and step by step, waded through the treacherous
                      waters.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* ------------------------------Krishna Leela---------------- */}
            <div className="row pt-5">
            <div
              className="col-md-6 tab-contents order-lg-1 order-2"
              id="kiran-bala-shagrithaya-hall"
            >
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className="">Krishna's Leela</h3>

                  <p>
                    Krishna's Leela encompasses a wide range of enchanting tales
                    and exploits. From his childhood in Vrindavan, where he
                    played mischievous pranks on the cowherd boys and stole
                    butter from the gopis (cowherd girls) to his teenage years
                    in Mathura, where he danced with the gopis and played the
                    flute to enchant them, Krishna's Leela showcases his divine
                    persona and multifaceted character. It also includes his
                    sweet interactions with his devotees, adorning Radha and his
                    other beloved gopis with his love and affection.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="banqImg">
                <img src={leela} alt="banquet halls" />
              </div>
            </div>
            </div>
            
            {/* ---------------------------slaying Kalia------------------- */}
            <div className="row pt-5">
            <div className="col-md-6">
              <div className="banqImg">
                <img src={kalinga} alt="banquet halls" />
              </div>
            </div>

            <div
              className="col-md-6 tab-contents order-lg-1 order-2"
              id="kiran-bala-shagrithaya-hall"
            >
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className=""> Slaying of Kalia </h3>

                  <p>
                    Slaying of Kalia - According to the story, the powerful
                    serpent Kalia dwelled in the Yamuna river, poisoning the
                    water and terrorizing the residents of Vrindavan. One day,
                    Lord Krishna, as a young boy, decided to confront Kalia and
                    put an end to his mischief. Krishna jumped into the river
                    and engaged in a fierce battle with Kalia. The serpent tried
                    to coil and crush Krishna, but Krishna, displaying his
                    divine powers, danced on Kalia's multiple hoods
                    effortlessly. Eventually, Krishna subdued Kalia and ordered
                    him to leave the river and the town.
                  </p>
                </div>
              </div>
            </div>
              </div>
          
            {/* -----------------------------Govardhan Hill--------------------- */}
            <div className="row pt-5">
            <div className="col-md-6 tab-contents order-lg-1 order-2" id="panoramic-view">
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className="">Govardhana Hill</h3>

                  <p>
                    The story of Lord Krishna and the Govardhana Hill is a
                    popular tale from Hindu mythology. It is known as the
                    "Govardhana Lila" or the lifting of Govardhana Giri.
                    According to the legend, once the people of Vrindavan
                    decided to offer their worship to the rain god, Indra, to
                    ensure proper rainfall for their crops and livelihood.
                    However, Lord Krishna advised them to redirect their
                    devotion to Govardhana Hill instead, as it was the hill that
                    provided them with fertile soil, grazing land for their
                    cows, and natural resources. The people of Vrindavan,
                    trusting Krishna's words, followed his instructions and
                    prepared a grand feast in honor of the Govardhana Hill.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="banqImg">
                <img src={govardhan} alt="banquet halls" />
              </div>
            </div>
              </div>
           

            {/* ----------------------jamvanti----------------------- */}
            <div className="row pt-5">
            <div className="col-md-6">
              <div className="banqImg">
                <img src={jamvanti} alt="terrace" />
              </div>
            </div>
            <div
              className="col-md-6 tab-contents order-lg-1 order-2"
              id="kiran-bala-shagrithaya-hall"
            >
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className="">Jambavati Kalyana</h3>

                  <p>
                    Jambavati Kalyana - Rukmini, wife of Lord Krishna, keeps
                    insisting on Krishna securing the precious gemstone
                    Semantaka Mani which possesses significant supernatural
                    powers. Ultimately, one fine day, Krishna agrees to get it
                    for her and goes in search of it. During the intense search,
                    he comes to know that it is with Jambavan and approaches
                    him. Jambavan does not recognize Lord Krishna, hence refuses
                    to give the gemstone. This results in a battle between the
                    two. But upon realizing Lord Krishna’s true identity,
                    Jambavan surrenders and becomes his steadfast ally. He gives
                    him the precious gemstone and requests Lord Krishna to marry
                    his daughter Jambavati. This beautiful union is depicted
                    here.
                  </p>
                </div>
              </div>
            </div>
              </div>
            
            {/* ---------------------tulabhra------------------- */}
            <div className="row pt-5">
            <div className="col-md-6">
              <div className="banqImg">
                <img src={tulabhra} alt="terrace" />
              </div>
            </div>
            <div className="col-md-6 tab-contents order-lg-1 order-2" id="panoramic-view">
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className="">Tulabhara</h3>

                  <p>
                    Tulabhara - One day, the two wives of Lord Krishna,
                    Satyabhama and Rukmini had an argument about whose love was
                    greatest towards Lord Krishna. Both decided to approach Lord
                    Krishna and posed him this difficult question. He said that
                    both had equal love towards him. Determined to prove that
                    her devotion was greater, Satyabhama approached Lord Krishna
                    and requested him to allow her to perform a Tulabhara, which
                    is a unique ceremony in which a person is weighed against
                    valuable items, and the equivalent weight of those items is
                    then offered as a donation.{" "}
                  </p>
                </div>
              </div>
            </div>
            </div>
            {/* ----------------------------draupadi------------------ */}
            <div className="row pt-5">
            <div
              className="col-md-6 tab-contents order-lg-1 order-2"
              id="kiran-bala-shagrithaya-hall"
            >
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className=""> Draupadi Vastraharan</h3>

                  <p>
                    Draupadi Vastraharan is a significant event that took place
                    in the Indian epic, Mahabharata. It refers to the disrobing
                    of Draupadi, the wife of the Pandavas, in the royal court of
                    Hastinapura. During a game of dice, the Pandavas lost
                    everything, including themselves and their wife Draupadi, to
                    the Kauravas. As a result, Duryodhana, the eldest Kaurava,
                    ordered Draupadi to be brought to the court and attempted to
                    strip her of her saree. However, through Lord Krishna’s
                    divine intervention, Draupadi's saree kept extending, saving
                    her honor. This event plays a crucial role in the narrative
                    of the Mahabharata and highlights the various injustices and
                    conflicts that unfolded during the epic.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="banqImg">
                <img src={draupadi} alt="banquet halls" />
              </div>
            </div>
            </div>
            {/* -----------------------------geeta---------------------- */}
            <div className="row pt-5">
            <div className="col-md-6">
              <div className="banqImg">
                <img src={geeta} alt="terrace" />
              </div>
            </div>
            <div className="col-md-6 tab-contents order-lg-1 order-2" id="panoramic-view">
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className=""> The Gitopadesha</h3>

                  <p>
                    The Gitopadesha mural refers to the teachings of the
                    Bhagavad Gita which is a conversation between Lord Krishna
                    and the warrior Arjuna that takes place on the battlefield
                    of Kurukshetra. In this dialogue, Lord Krishna imparts
                    spiritual wisdom and guidance to Arjuna, who is facing moral
                    dilemmas and doubts about participating in the Mahabharata
                    war. The Gitopadesha encompasses various philosophical and
                    ethical teachings, including the concepts of duty,
                    righteousness, selflessness, and the paths to spiritual
                    liberation.{" "}
                  </p>
                </div>
              </div>
            </div>
            </div>
            {/* ----------------------------sudhama---------------------- */}
            <div className="row pt-5">
            <div
              className="col-md-6 tab-contents order-lg-1 order-2"
              id="kiran-bala-shagrithaya-hall"
            >
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className="">Bhaktha Sudama</h3>

                  <p>
                    Bhaktha Sudama is a popular tale from Hindu mythology.
                    Sudama was a childhood friend of Lord Krishna, and they grew
                    up together in the village of Vrindavan. Despite the stark
                    difference in their social statuses, Sudama being poor and
                    Krishna being a prince, their friendship remained strong.
                    One day, Sudama's wife urged him to seek help from Lord
                    Krishna as they were going through extreme poverty. Sudama
                    hesitantly agreed and embarked on a journey to visit Krishna
                    in his kingdom. Sudama carried a humble gift of a handful of
                    beaten rice for his friend.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="banqImg">
                <img src={sudhama} alt="banquet halls" />
              </div>
            </div>
            </div>
            <div className="row pt-5">
            <div className="col-md-6">
              <div className="banqImg">
                <img src={wed} alt="terrace" />
              </div>
            </div>
            <div className="col-md-6 tab-contents order-lg-1 order-2" id="panoramic-view">
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className="">Lord Srinivasa Kalyanam</h3>

                  <p>
                    The story of Lord Srinivasa Kalyanam is a significant part
                    of Hindu mythology and is often depicted here. Kalyanam
                    refers to a divine marriage or wedding ceremony. The story
                    revolves around Lord Srinivasa, also known as Lord
                    Venkateswara, and his marriage with Goddess Padmavati.
                    According to the legend, Lord Srinivasa took the form of a
                    deity and resided in the Venkatachala hills. One day, he met
                    a beautiful princess named Padmavati in a forest while she
                    was performing a penance to seek Lord Vishnu as her husband.
                    Impressed by her devotion, Lord Srinivasa decided to marry
                    her.
                  </p>
                </div>
              </div>
            </div>
            </div>
            <div className="row pt-5">
            <div
              className="col-md-6 tab-contents order-lg-1 order-2"
              id="kiran-bala-shagrithaya-hall"
            >
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className="">Gajendra Moksha </h3>

                  <p>
                    The story of Gajendra Moksha symbolizes the power of
                    unwavering devotion and surrender to the divine. It teaches
                    us that even in the most challenging situations, calling
                    upon a higher power with sincere devotion can lead to
                    liberation and salvation. Once, Gajendra, the king of
                    elephants, was enjoying himself in a beautiful lake, along
                    with his family and friends. Suddenly, a crocodile clamped
                    onto his leg and started dragging him into the water.
                    Gajendra tried his best to escape but was unable to free
                    himself from the crocodile's grip.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="banqImg">
                <img src={gajendra} alt="banquet halls" />
              </div>
            </div>
            </div>
            {/* -------------Vishwaroopa Darshana------------------------ */}
            <div className="row pt-5">
            <div className="col-md-6">
              <div className="banqImg">
                <img src={vishwa} alt="terrace" />
              </div>
            </div>
            <div className="col-md-6 tab-contents order-lg-1 order-2" id="panoramic-view">
              <div className="tab-content">
                <div role="tabpanel" className="tab-pane active" id="wwd2018">
                  <h3 className=""> Vishwaroopa Darshana</h3>

                  <p>
                    Vishwaroopa Darshana, also known as the Cosmic Form or
                    Universal Form, is a significant event described in the
                    Bhagavad Gita. It occurs when Lord Krishna reveals his
                    divine and universal form to Arjuna, a warrior and disciple,
                    on the battlefield of Kurukshetra. This form showcases Lord
                    Krishna's divine power, grandeur, and cosmic nature. It
                    includes countless faces, eyes, arms, and divine beings from
                    various realms. It represents the interconnectedness of all
                    beings and the divine essence that permeates the universe.
                  </p>
                </div>
              </div>
            </div>
            </div>
           
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Murals;
